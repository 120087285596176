import { JsonObject, JsonProperty } from 'json2typescript'
import Address from '../Address'
import Model from '../Model'
import { DefinedListDetail } from '../DefinedList'
import VehicleConviction from './VehicleConviction'
import DateConverter from '@/converters/DateConverter'

@JsonObject('VehicleDriverModel')
export default class VehicleDriver extends Model {
  @JsonProperty('title', DefinedListDetail)
    title: DefinedListDetail

  @JsonProperty('firstName', String)
    firstName: string

  @JsonProperty('lastName', String)
    lastName: string

  @JsonProperty('dateOfBirth', DateConverter)
    dateOfBirth: Date

  @JsonProperty('licenceNumber', String, true)
    licenceNumber: string | null = null

  @JsonProperty('licenceType', DefinedListDetail)
    licenceType: DefinedListDetail

  @JsonProperty('licenceHeldYears', DefinedListDetail)
    licenceHeldYears: DefinedListDetail

  @JsonProperty('hasAccessToRegularVehicleUse', Boolean)
    hasAccessToRegularVehicleUse: boolean

  @JsonProperty('regularUseVehicleDetail', String, true)
    regularUseVehicleDetail: string | null = null

  @JsonProperty('relationshipToPolicyHolder', DefinedListDetail)
    relationshipToPolicyHolder: DefinedListDetail

  @JsonProperty('hasAccessToAnotherVehicle', Boolean)
    hasAccessToAnotherVehicle: boolean

  @JsonProperty('address', Address, true)
    address: Address | null = null

  @JsonProperty('driverToVehicles', [String])
    driverToVehicles: string[] | null = null

  @JsonProperty('proposerHasAccessToAdditionalDriverLicence', Boolean, true)
    proposerHasAccessToAdditionalDriverLicence: boolean | null = null

  @JsonProperty('hasConvictions', Boolean)
    hasConvictions: boolean | null = null

  @JsonProperty('convictions', [VehicleConviction])
    convictions: VehicleConviction[]

  constructor(
      id = 0,
      title: DefinedListDetail = new DefinedListDetail(),
      firstName = '',
      lastName = '',
      dateofBirth: Date = new Date(),
      licenceNumber = '',
      licenceType: DefinedListDetail = new DefinedListDetail(),
      licenceHeldYears: DefinedListDetail = new DefinedListDetail(),
      hasAccessToRegularVehicleUse = false,
      relationshipToPolicyHolder: DefinedListDetail = new DefinedListDetail(),
      address = new Address(),
      hasAccessToAnotherVehicle = false,
      driverToVehicles: string[] = [],
      regularUseVehicleDetail = '',
      proposerHasAccessToAdditionalDriverLicence = false,
      hasConvictions = null,
      convictions: VehicleConviction[] = [],
  ) {
    super(id)
    this.title = title
    this.firstName = firstName
    this.lastName = lastName
    this.dateOfBirth = dateofBirth
    this.licenceNumber = licenceNumber
    this.licenceType = licenceType
    this.licenceHeldYears = licenceHeldYears
    this.hasAccessToRegularVehicleUse = hasAccessToRegularVehicleUse
    this.relationshipToPolicyHolder = relationshipToPolicyHolder
    this.address = address
    this.hasAccessToAnotherVehicle = hasAccessToAnotherVehicle
    this.driverToVehicles = driverToVehicles
    this.regularUseVehicleDetail = regularUseVehicleDetail
    this.proposerHasAccessToAdditionalDriverLicence = proposerHasAccessToAdditionalDriverLicence
    this.hasConvictions = hasConvictions
    this.convictions = convictions
  }
}
