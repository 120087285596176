import Hotjar from '@hotjar/browser'
import _Vue from 'vue'
import VueGtag from 'vue-gtag'
import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type Risk from '@/services/models/Risk'
import { PetRisk } from '@/services/models/Risk'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import useAggregatorStore from '@/stores/aggregator-store'

export default class KeeyPartnersAnalytics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    const gtmId = window.injectedEnv.FRONTEND_GTM
    if (gtmId) {
      (function loadGTM(w: any, d: any, s: any, l: any, i: any) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? `&l=${l}` : ''
        j.async = true
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', gtmId)
    }

    // Google Analytics
    _Vue.use(VueGtag, {
      config: {
        id: config.analytics,
        params: {

          send_page_view: false,
        },
      },
    }, config.router)

    // Hotjar Tracking
    Hotjar.init(Number(window.injectedEnv.FRONTEND_HOTJAR), 6)
  }

  trackNBSale<T extends SchemeQuoteResult>(vue: Vue, policyNumber: string, quote: QuoteDetails<T>, selectedSchemeQuoteResult: number, aggregatorClient: AggregatorClient | null, risk: Risk | null): void {
    const result = quote.results.find(r => r.id === selectedSchemeQuoteResult)!

    // Google Tag Manager
    this.window.dataLayer?.push({
      policyNumber,
      schemeName: result.schemeName,
      premium: result.calculatedTotalPayable.toString(10),
      additional: quote.additional.toString(10),
      aggregator: aggregatorClient?.name ?? '',
      event: 'nb-sale',
      policyType: risk instanceof PetRisk ? (risk?.pets.length > 1 ? 'multipet' : 'single') : '',
    })

    if (aggregatorClient?.name.includes('Confused') || aggregatorClient?.name.includes('Compare Cover')) {
      const aggregatorStore = useAggregatorStore()
      const ref = aggregatorStore.queryString.ref
      if (window.location.origin === 'https://community.scratchandpatch.co.uk') {
        try {
          const img = document.createElement('img')
          img.src = `https://www.quote-link.net/petcover/salestracking/petquotes_sale_confirmation.cfm?ref=${ref}&fullprem=${result.calculatedTotalPayable.toString(10)}`
          img.width = 1
          img.height = 1
          document.body.appendChild(img)
          // eslint-disable-next-line no-console
          console.log(`SNP EVENT FIRED --- ${aggregatorClient?.name} agg takeup event sent. ${ref ? `Ref: ${ref}` : null}`)
        }
        catch (error) {
          console.error('Unable to send agg takeup event', error)
        }
      }
      else {
        // eslint-disable-next-line no-console
        console.log(`DUMMY SNP EVENT FIRED --- ${aggregatorClient?.name} agg takeup event sent. ${ref ? `Ref: ${ref}` : null}`)
      }
    }

    // Direct to Google Analytics sale tracking
    vue.$gtag?.purchase({

      transaction_id: policyNumber,
      affiliation: 'Ignite CF',
      value: result.calculatedTotalPayable,
      items: [
        {
          id: result.scheme.code,
          name: result.schemeName,
        },
      ],
    })
  }
}
