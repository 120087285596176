import moment from 'moment'
import type { Dictionary } from 'vue-gtag'
import ContextLevel from '../enums/ContextLevel'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import QuestionEnum from '../enums/QuestionEnum'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import type IPageMappings from '../interfaces/IPageMappings'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import type Address from '../models/Address'
import type ProductPage from '../models/ProductPage'
import VehicleProposer from '../values/Vehicle/VehicleProposer'
import AccountRegister from '../values/AccountRegister'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type QuoteRequest from '../values/QuoteRequest'
import type SchemeRequest from '../values/SchemeRequest'
import VehicleQuoteRequest, { VehicleMTAQuoteRequest } from '../values/Vehicle/VehicleQuoteRequest'
import type VehicleRisk from '../models/Vehicle/VehicleRisk'
import Vehicle from '../values/Vehicle'
import type VehicleRiskVehicle from '../values/Vehicle/VehicleRiskVehicle'
import type VehicleConvictionValue from '../values/Vehicle/VehicleConviction'
import type VehicleDriver from '../values/Vehicle/VehicleDriver'
import type VehicleConvictionModel from '../models/Vehicle/VehicleConviction'
import type VehicleModificationValue from '../values/Vehicle/VehicleModification'
import type VehicleModificationModel from '../models/Vehicle/VehicleModification'
import BaseMapper from './BaseMapper'
import type {
  AddressQuestion,
  ConvictionsQuestion,
  DateQuestion,
  DrivingLicenceQuestion,
  ModificationQuestion,
  NumberQuestion,
  SelectQuestion,
  TextQuestion,
} from '@/view-models/Question'
import type Page from '@/view-models/Page'
import Routes from '@/constants/Routes'

export default class HagertyMapper extends BaseMapper implements IRequestMapper, IResponseMapper, IQuestionMapper, IPageMappings {
  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented. Hagerty does not use Scheme Requests.')
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. Hagerty does not use custom validation.')
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    // Personal details
    const isCompany = questions.find(q => q.name === QuestionEnum.policyholderType) as QuestionAnswer<string>
    const companyName = questions.find(q => q.name === QuestionEnum.companyName) as QuestionAnswer<string>
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const licenceNumber = questions.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
    const hasAccessToProposerLicence = questions.find(q => q.name === QuestionEnum.hasAccessToProposerLicence) as QuestionAnswer<string>
    const licenceType = questions.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
    const licenceHeldYears = questions.find(q => q.name === QuestionEnum.licenceHeldYears) as QuestionAnswer<string>
    const hasConvictions = questions.find(q => q.name === QuestionEnum.hasConvictions) as QuestionAnswer<string>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const clubMembership = questions.find(q => q.name === QuestionEnum.clubName) as QuestionAnswer<string>
    const referralSource = questions.find(q => q.name === QuestionEnum.referralSource) as QuestionAnswer<number>
    const voluntaryExcess = questions.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>
    const hasAccessToAnotherVehicle = questions.find(q => q.name === QuestionEnum.hasAccessToAnotherVehicle) as QuestionAnswer<string>
    const hasAccessToRegularVehicleUse = questions.find(q => q.name === QuestionEnum.hasAccessToRegularVehicleUse) as QuestionAnswer<string>
    const regularUseVehicleDetail = questions.find(q => q.name === QuestionEnum.regularUseVehicleDetail) as QuestionAnswer<string>
    const isCompanyVehicle = questions.find(q => q.name === QuestionEnum.isCompanyVehicle) as QuestionAnswer<string>

    // Convictions
    const convictions = this.getConvictions(questions)

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }

    // Vehicle details
    const additionalVehicles = questions.find(q => q.name === QuestionEnum.additionalVehicle) as QuestionAnswer<number>
    const vehicles: VehicleRiskVehicle[] = []

    if (additionalVehicles) {
      for (let i = 0; i < additionalVehicles.subPageAnswers.length; i += 1) {
        const adVehicleType = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.typeOfVehicle) as QuestionAnswer<string>
        const adVehicleLookup = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
        const adVehicleValue = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleValue) as QuestionAnswer<number>
        const adClassOfUse = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.classOfUse) as QuestionAnswer<string>
        const adRiderWillCarryPillion = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.riderWillCarryPillion) as QuestionAnswer<string>
        const adAnnualMileage = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as QuestionAnswer<string>
        const adIsModified = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleIsModified) as QuestionAnswer<string>
        const adIsOwnedByProposer = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleOwner) as QuestionAnswer<string>
        const adIsPrivatelyStored = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.isPrivatelyStored) as QuestionAnswer<string>
        const adVehicleStorage = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleStorage) as QuestionAnswer<string>
        const adVehicleCondition = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleCondition) as QuestionAnswer<string>
        const adVoluntaryExcess = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>
        const adVehicleSecurityDevice = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleSecurityDevice) as QuestionAnswer<string>
        const adEuropeanCover = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.europeanCover) as QuestionAnswer<string>
        const adLengthOfOwnership = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.ownerVehicleYears) as QuestionAnswer<string>
        const adVehicleAddress = additionalVehicles.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleAddress) as QuestionAnswer<Address>

        vehicles.push({
          vehicleType: adVehicleType.value!,
          registration: adVehicleLookup.value!.registration,
          vin: adVehicleLookup.value!.vin!,
          abiCode: adVehicleLookup.value!.abiCode,
          make: adVehicleLookup.value!.make,
          model: adVehicleLookup.value!.model,
          cubicCapacity: adVehicleLookup.value!.engineSize,
          value: adVehicleValue.value!,
          yearOfMake: adVehicleLookup.value!.yearOfManufacture!,
          purchaseDate: adVehicleLookup.value!.purchaseDate!,
          classOfUse: adClassOfUse.value!,
          riderWillCarryPillion: adRiderWillCarryPillion?.value === DLDCommonValuesEnum.Boolean.Yes,
          annualMileage: adAnnualMileage.value!,
          isModified: adIsModified.value! === DLDCommonValuesEnum.Boolean.Yes,
          modifications: adIsModified.value! === DLDCommonValuesEnum.Boolean.Yes ? this.getModifications(additionalVehicles.subPageAnswers[i].answers) : [],
          isOwnedByProposer: adIsOwnedByProposer.value! === DLDCommonValuesEnum.Boolean.Yes ? DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder : DLDCommonValuesEnum.RegisteredKeeper.Other,
          isPrivatelyStored: (adIsPrivatelyStored && adIsPrivatelyStored.value! === DLDCommonValuesEnum.Boolean.Yes) ?? true,
          vehicleStorage: adVehicleStorage.value!,
          vehicleCondition: adVehicleCondition.value!,
          voluntaryExcess: adVoluntaryExcess.value!,
          securityDevice: adVehicleSecurityDevice.value!,
          europeanCover: adEuropeanCover ? adEuropeanCover.value! : DLDCommonValuesEnum.EuropeanCoverDays.Days90,
          lengthOfOwnership: adLengthOfOwnership.value!,
          address: adVehicleAddress.value!,
        })
      }
    }

    // Addtional drivers
    const additionalDrivers = questions.find(q => q.name === QuestionEnum.additionalDriver) as QuestionAnswer<number>
    const vehicleDrivers: VehicleDriver[] = []

    if (additionalDrivers) {
      for (let i = 0; i < additionalDrivers.subPageAnswers.length; i += 1) {
        const adTitle = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
        const adFirstName = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
        const adLastName = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
        const adRelationshipToPolicyholder = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.relationshipToPolicyHolder) as QuestionAnswer<string>
        const adDateOfBirth = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
        const adDrivingLicence = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
        const adHasAccessToAnotherVehicle = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasAccessToAnotherVehicle) as QuestionAnswer<string>
        const adHasAccessToRegularVehicleUse = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasAccessToRegularVehicleUse) as QuestionAnswer<string>
        const adRegularUseVehicleDetail = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.regularUseVehicleDetail) as QuestionAnswer<string>
        const adAddress = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
        const adDriverToVehicles = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.driverToVehicles) as QuestionAnswer<string[]>
        const adHasAccessToAdditionalDriverLicence = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasAccessToAdditionalDriverLicence) as QuestionAnswer<string>
        const adLicenceType = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.licenceType) as QuestionAnswer<string>
        const adLicenceHeldYears = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.licenceHeldYears) as QuestionAnswer<string>
        const adHasConvictions = additionalDrivers.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasConvictions) as QuestionAnswer<string>

        vehicleDrivers.push({
          title: adTitle.value!,
          firstName: adFirstName.value!,
          lastName: adLastName.value!,
          relationshipToPolicyHolder: adRelationshipToPolicyholder.value!,
          dateOfBirth: adDateOfBirth.value!,
          proposerHasAccessToAdditionalDriverLicence: adHasAccessToAdditionalDriverLicence?.value ? adHasAccessToAdditionalDriverLicence?.value === DLDCommonValuesEnum.Boolean.Yes : null,
          licenceNumber: adHasAccessToAdditionalDriverLicence?.value === DLDCommonValuesEnum.Boolean.Yes ? adDrivingLicence.value! : null,
          licenceType: adHasAccessToAdditionalDriverLicence.value! === DLDCommonValuesEnum.Boolean.No ? adLicenceType.value! : DLDCommonValuesEnum.CarLicenceType.FullUK,
          licenceHeldYears: adHasAccessToAdditionalDriverLicence.value! === DLDCommonValuesEnum.Boolean.No ? adLicenceHeldYears.value! : null,
          address: adAddress?.value ?? null,
          hasAccessToAnotherVehicle: adHasAccessToAnotherVehicle.value! === DLDCommonValuesEnum.Boolean.Yes,
          hasAccessToRegularVehicleUse: adHasAccessToRegularVehicleUse?.value === DLDCommonValuesEnum.Boolean.Yes,
          regularUseVehicleDetail: adRegularUseVehicleDetail?.value ?? null,
          driverToVehicles: adDriverToVehicles.value!,
          hasConvictions: adHasAccessToAdditionalDriverLicence.value! === DLDCommonValuesEnum.Boolean.No ? adHasConvictions?.value === DLDCommonValuesEnum.Boolean.Yes : null,
          convictions: adHasAccessToAdditionalDriverLicence.value! === DLDCommonValuesEnum.Boolean.No && hasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes ? this.getConvictions(additionalDrivers.subPageAnswers[i].answers) : [],
        })
      }
    }

    // Cover details
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>
    const currentInsurer = questions.find(q => q.name === QuestionEnum.currentInsurer) as QuestionAnswer<string>

    const quoteRequest = new Type()

    if (!(quoteRequest instanceof VehicleQuoteRequest || quoteRequest instanceof VehicleMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Hagerty.`)
    // Proposer Details
    quoteRequest.vehicleProposer = new VehicleProposer()
    quoteRequest.vehicleProposer.isCompany = isCompany?.value === DLDCommonValuesEnum.PolicyholderType.Company
    quoteRequest.vehicleProposer.companyName = isCompany?.value === DLDCommonValuesEnum.PolicyholderType.Company ? companyName.value! : null
    quoteRequest.vehicleProposer.title = title.value!
    quoteRequest.vehicleProposer.firstName = firstName.value!
    quoteRequest.vehicleProposer.lastName = lastName.value!
    quoteRequest.vehicleProposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.vehicleProposer.hasAccessToProposerLicence = hasAccessToProposerLicence?.value ? hasAccessToProposerLicence?.value === DLDCommonValuesEnum.Boolean.Yes : null
    quoteRequest.vehicleProposer.licenceNumber = hasAccessToProposerLicence?.value === DLDCommonValuesEnum.Boolean.Yes ? licenceNumber.value! : null
    quoteRequest.vehicleProposer.licenceType = hasAccessToProposerLicence.value! === DLDCommonValuesEnum.Boolean.No ? licenceType.value! : DLDCommonValuesEnum.CarLicenceType.FullUK
    quoteRequest.vehicleProposer.licenceHeldYears = hasAccessToProposerLicence.value! === DLDCommonValuesEnum.Boolean.No ? licenceHeldYears.value! : null
    quoteRequest.address = address.value!
    quoteRequest.vehicleProposer.address = address.value!
    quoteRequest.vehicleProposer.hasAccessToRegularVehicleUse = hasAccessToRegularVehicleUse?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.vehicleProposer.hasAccessToAnotherVehicle = hasAccessToAnotherVehicle.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.vehicleProposer.regularUseVehicleDetail = regularUseVehicleDetail?.value ?? null
    quoteRequest.vehicleProposer.isCompanyVehicle = isCompanyVehicle?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.vehicleProposer.clubMembership = clubMembership.value!
    quoteRequest.vehicleProposer.hasConfirmedAssumptions = true
    quoteRequest.vehicleProposer.hasAdditionalDrivers = vehicleDrivers.length > 0
    quoteRequest.vehicleProposer.hadInsuranceRefusedCancelledOrDeclined = false
    quoteRequest.vehicleProposer.hasUnspentNonMotoringConvictions = false
    quoteRequest.vehicleProposer.hasDeclaredBankruptcy = false
    quoteRequest.vehicleProposer.hadPreviousInsurance = currentInsurer?.value !== DLDCommonValuesEnum.CurrentInsurer.NotListed
    quoteRequest.vehicleProposer.currentInsurer = currentInsurer?.value
    quoteRequest.vehicleProposer.hasConvictions = hasAccessToProposerLicence.value! === DLDCommonValuesEnum.Boolean.No ? hasConvictions?.value === DLDCommonValuesEnum.Boolean.Yes : null

    // Convictions
    quoteRequest.vehicleProposer.convictions = hasAccessToProposerLicence.value! === DLDCommonValuesEnum.Boolean.No && hasConvictions.value! === DLDCommonValuesEnum.Boolean.Yes ? convictions : []

    // Additional Driver Details
    quoteRequest.vehicleDrivers = vehicleDrivers

    // Vehicle Details
    quoteRequest.vehicles = vehicles

    // Cover Details
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.voluntaryExcess = voluntaryExcess?.value ?? DLDCommonValuesEnum.VolXs.VolXs0
    quoteRequest.coverType = DLDCommonValuesEnum.CoverType.Comprehensive
    quoteRequest.businessSourceId = referralSource?.value

    return quoteRequest
  }

  getMTARequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new() => T): T {
    const mtaRequest = this.getQuoteRequest(questions, Type)

    if (!(mtaRequest instanceof VehicleMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for Hagerty`)

    const reasonForChange = questions.find(q => q.name === QuestionEnum.reasonForChange) as QuestionAnswer<string>
    mtaRequest.reasonForChange = reasonForChange.value!
    return mtaRequest
  }

  private getConvictions(questions: IQuestionAnswer[]): VehicleConvictionValue[] {
    const convictionsQuestion = questions.find(q => q.name === QuestionEnum.convictions) as QuestionAnswer<number>
    const convictions: VehicleConvictionValue[] = []

    if (convictionsQuestion) {
      for (let i = 0; i < convictionsQuestion.subPageAnswers.length; i += 1) {
        const convictionDate = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionDate) as QuestionAnswer<Date>
        const convictionType = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionType) as QuestionAnswer<string>
        const convictionPersonsInjured = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionPersonsInjured) as QuestionAnswer<string>
        const hasConvictionBan = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.hasConvictionBan) as QuestionAnswer<string>
        const banStartDate = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.banStartDate) as QuestionAnswer<Date>
        const banEndDate = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.banEndDate) as QuestionAnswer<Date>
        const convictionFine = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionFine) as QuestionAnswer<number>
        const convictionPointsReceived = convictionsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.convictionPointsReceived) as QuestionAnswer<number>

        convictions.push({
          convictionDate: convictionDate.value!,
          convictionType: convictionType.value!,
          convictionPersonsInjured: convictionPersonsInjured.value! === DLDCommonValuesEnum.Boolean.Yes,
          hasConvictionBan: hasConvictionBan.value! === DLDCommonValuesEnum.Boolean.Yes,
          banStartDate: hasConvictionBan.value! === DLDCommonValuesEnum.Boolean.Yes ? banStartDate.value! : null,
          banEndDate: hasConvictionBan.value! === DLDCommonValuesEnum.Boolean.Yes ? banEndDate.value! : null,
          convictionFine: convictionFine.value!,
          convictionPointsReceived: convictionPointsReceived.value!,
        })
      }
    }
    return convictions
  }

  private getModifications(questions: IQuestionAnswer[]): VehicleModificationValue[] {
    const modificationsQuestion = questions.find(q => q.name === QuestionEnum.modifications) as QuestionAnswer<number>
    const modifications: VehicleModificationValue[] = []

    if (modificationsQuestion) {
      for (let i = 0; i < modificationsQuestion.subPageAnswers.length; i += 1) {
        const modificationSubCategory = modificationsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleModificationSubCategory) as QuestionAnswer<string>
        const modificationDetail = modificationsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.vehicleModifications) as QuestionAnswer<string>

        modifications.push({
          modificationSubCategory: modificationSubCategory.value!,
          modificationDetail: modificationDetail.value!,
        })
      }
    }
    return modifications
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister | null {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const autoRenew = questions.find(q => q.name === QuestionEnum.autoRenew) as QuestionAnswer<boolean>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>

    if (!title?.value || !firstName?.value || !lastName?.value || !email?.value || !address?.value)
      return null

    const request = new AccountRegister()
    request.title = title.value!
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.email = email.value!
    request.dateOfBirth = dob.value!
    request.address = address.value!
    request.mobilePhone = phoneNumber.value!
    request.autoRenew = autoRenew?.value

    request.marketing = new Marketing()
    request.marketing.isEmailOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
    request.marketing.isTelephoneOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    request.marketing.isSMSOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
    request.marketing.isPostOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1

    request.affiliateId = affiliateId

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const autoRenew = questions.find(q => q.name === QuestionEnum.autoRenew) as QuestionAnswer<boolean>

    account.autoRenew = autoRenew?.value ?? account.autoRenew

    account.title = title?.value ?? account.title
    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone

    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address

    return account
  }

  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    Vehicle_Assumptions_CustomerFacing: { route: Routes.preQuoteAssumptions, contextLevel: ContextLevel.preQuote },
    Vehicle_Proposer_Section1_CustomerFacing: { route: Routes.proposerSection1, contextLevel: ContextLevel.preQuote },
    Vehicle_Proposer_Section2_CustomerFacing: { route: Routes.proposerSection2, contextLevel: ContextLevel.preQuote },
    Vehicle_Proposer_Section3_CustomerFacing: { route: Routes.proposerSection3, contextLevel: ContextLevel.preQuote },
    Vehicle_Vehicles_Summary_CustomerFacing: { route: Routes.vehicleDetails, contextLevel: ContextLevel.preQuote },
    Vehicle_Drivers_Section1_CustomerFacing: { route: Routes.driverDetails, contextLevel: ContextLevel.preQuote },
    Vehicle_Proposer_Section4_CustomerFacing: { route: Routes.proposerSection4, contextLevel: ContextLevel.preQuote },
    Vehicle_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    Vehicle_Premium: { route: Routes.premium, contextLevel: ContextLevel.quote },
    Vehicle_Payment_CustomerFacing: { route: Routes.payment, contextLevel: ContextLevel.quote },
    Vehicle_PaymentConfirmation_CustomerFacing: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    Vehicle_AssumptionsMta_CustomerFacing: { route: Routes.mtaPreQuoteAssumptions, contextLevel: ContextLevel.mtaPreQuote },
    Vehicle_ProposerMta_Section1_CustomerFacing: { route: Routes.mtaProposerSection1, contextLevel: ContextLevel.mtaPreQuote },
    Vehicle_ProposerMta_Section2_CustomerFacing: { route: Routes.mtaProposerSection2, contextLevel: ContextLevel.mtaPreQuote },
    Vehicle_ProposerMta_Section3_CustomerFacing: { route: Routes.mtaProposerSection3, contextLevel: ContextLevel.mtaPreQuote },
    Vehicle_VehiclesMta_Summary_CustomerFacing: { route: Routes.mtaVehicleDetails, contextLevel: ContextLevel.mtaPreQuote },
    Vehicle_DriversMta_Section1_CustomerFacing: { route: Routes.mtaDriverDetails, contextLevel: ContextLevel.mtaPreQuote },
    Vehicle_ProposerMta_Section4_CustomerFacing: { route: Routes.mtaProposerSection4, contextLevel: ContextLevel.mtaPreQuote },
    Vehicle_PremiumMta_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    Vehicle_Payment_CustomerFacing: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    Vehicle_PaymentConfirmation_CustomerFacing: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    Vehicle_AssumptionsRenewal_CustomerFacing: { route: Routes.renewalAssumptions, contextLevel: ContextLevel.renewalPreQuote },
    Vehicle_ProposerRenewals_Section1_CustomerFacing: { route: Routes.renewalProposerSection1, contextLevel: ContextLevel.renewalPreQuote },
    Vehicle_ProposerRenewals_Section2_CustomerFacing: { route: Routes.renewalProposerSection2, contextLevel: ContextLevel.renewalPreQuote },
    Vehicle_ProposerRenewals_Section3_CustomerFacing: { route: Routes.renewalProposerSection3, contextLevel: ContextLevel.renewalPreQuote },
    Vehicle_VehiclesRenewals_Summary_CustomerFacing: { route: Routes.renewalVehicleDetails, contextLevel: ContextLevel.renewalPreQuote },
    Vehicle_DriversRenewals_Section1_CustomerFacing: { route: Routes.renewalDriverDetails, contextLevel: ContextLevel.renewalPreQuote },
    Vehicle_ProposerRenewals_Section4_CustomerFacing: { route: Routes.renewalProposerSection4, contextLevel: ContextLevel.renewalPreQuote },
    Vehicle_PremiumRenewal_CustomerFacing: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    Vehicle_Payment_CustomerFacing: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    Vehicle_PaymentConfirmation_CustomerFacing: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
  }

  setupPage(setId: string, productPage: ProductPage, answers: IQuestionAnswer[]): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage, answers)
  }

  setupDependencies(setId: string, mappedPage: Page, answers: IQuestionAnswer[]): Page {
    switch (setId) {
      case 'Vehicle_Assumptions_CustomerFacing':
      case 'Vehicle_AssumptionsRenewal_CustomerFacing':
      case 'Vehicle_AssumptionsMta_CustomerFacing':
        return mappedPage
      case 'Vehicle_Proposer_Section1_CustomerFacing':
      case 'Vehicle_ProposerRenewals_Section1_CustomerFacing':
      case 'Vehicle_ProposerMta_Section1_CustomerFacing':
        return this.setupProposer1Dependencies(mappedPage)
      case 'Vehicle_Proposer_Section2_CustomerFacing':
      case 'Vehicle_ProposerRenewals_Section2_CustomerFacing':
      case 'Vehicle_ProposerMta_Section2_CustomerFacing':
        return this.setupProposer2Dependencies(mappedPage, answers)
      case 'Vehicle_Proposer_Section3_CustomerFacing':
      case 'Vehicle_ProposerRenewals_Section3_CustomerFacing':
      case 'Vehicle_ProposerMta_Section3_CustomerFacing':
        return this.setupProposer3Dependencies(mappedPage, answers)
      case 'Vehicle_Vehicles_Summary_CustomerFacing':
      case 'Vehicle_VehiclesRenewals_Summary_CustomerFacing':
      case 'Vehicle_VehiclesMta_Summary_CustomerFacing':
        return mappedPage
      case 'Vehicle_Vehicles_CustomerFacing_AdditionalVehicle':
      case 'Vehicle_Vehicles_CustomerFacing_AdditionalVehicle_Renewals':
        return this.setupAdditionalVehicleDependencies(mappedPage, answers)
      case 'Vehicle_Vehicles_CustomerFacing_AdditionalVehicle_Mta':
        return this.setupAdditionalVehicleDependencies(mappedPage, answers, true)
      case 'Vehicle_Vehicles_CustomerFacing_AdditionalVehicle_Modifications':
      case 'Vehicle_Vehicles_CustomerFacing_AdditionalVehicle_Renewals_Modifications':
      case 'Vehicle_Vehicles_CustomerFacing_AdditionalVehicle_Mta_Modifications':
      case 'Vehicle_Drivers_Section1_CustomerFacing':
      case 'Vehicle_DriversRenewals_Section1_CustomerFacing':
      case 'Vehicle_DriversMta_Section1_CustomerFacing':
      case 'Vehicle_VAPs_CustomerFacing':
        return mappedPage
      case 'Vehicle_Drivers_CustomerFacing_AdditionalDriver':
      case 'Vehicle_Drivers_CustomerFacing_AdditionalDriver_Renewals':
      case 'Vehicle_Drivers_CustomerFacing_AdditionalDriver_Mta':
        return this.setupAdditionalDriverDependencies(mappedPage)
      case 'Vehicle_Proposer_Section4_CustomerFacing':
      case 'Vehicle_ProposerRenewals_Section4_CustomerFacing':
      case 'Vehicle_ProposerMta_Section4_CustomerFacing':
        return this.setupProposer4Dependencies(mappedPage)
      case 'Vehicle_Premium':
      case 'Vehicle_PremiumRenewal_CustomerFacing':
      case 'Vehicle_PremiumMta_CustomerFacing':
      case 'Vehicle_Payment_CustomerFacing':
      case 'Vehicle_PaymentConfirmation_CustomerFacing':
      case 'Account':
        return mappedPage
      case 'Vehicle_Proposer_CustomerFacing_Convictions':
      case 'Vehicle_ProposerRenewals_CustomerFacing_Convictions':
      case 'Vehicle_ProposerMta_CustomerFacing_Convictions':
      case 'Vehicle_Drivers_CustomerFacing_AdditionalDriver_Convictions':
      case 'Vehicle_Drivers_CustomerFacing_AdditionalDriver_Renewals_Convictions':
      case 'Vehicle_Drivers_CustomerFacing_AdditionalDriver_Mta_Convictions':
        return this.setupConvictionDependencies(mappedPage, answers)
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setupProposer1Dependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    // licence number
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    // Self-Declared Convictions
    const hasAccessToProposerLicenceQuestion = questions.find(q => q.name === QuestionEnum.hasAccessToProposerLicence) as SelectQuestion<string, string>
    const drivingLicenceTypeQuestion = questions.find(q => q.name === QuestionEnum.licenceType) as SelectQuestion<string, string>
    const licenceHeldYearsQuestion = questions.find(q => q.name === QuestionEnum.licenceHeldYears) as SelectQuestion<string, string>
    const hasConvictionsQuestion = questions.find(q => q.name === QuestionEnum.hasConvictions) as SelectQuestion<string, string>
    const convictionsQuestion = questions.find(q => q.name === QuestionEnum.convictions) as ConvictionsQuestion
    convictionsQuestion.minSubPages = 1

    if (hasAccessToProposerLicenceQuestion && hasAccessToProposerLicenceQuestion.visible) {
      drivingLicenceQuestion.isVisible = () => hasAccessToProposerLicenceQuestion.value === DLDCommonValuesEnum.Boolean.Yes
      drivingLicenceTypeQuestion.isVisible = licenceHeldYearsQuestion.isVisible = hasConvictionsQuestion.isVisible = () => hasAccessToProposerLicenceQuestion.value! === DLDCommonValuesEnum.Boolean.No
      convictionsQuestion.isVisible = () => hasAccessToProposerLicenceQuestion.value! === DLDCommonValuesEnum.Boolean.No && hasConvictionsQuestion?.value != null && hasConvictionsQuestion?.value === DLDCommonValuesEnum.Boolean.Yes
    }
    else {
      drivingLicenceTypeQuestion.isVisible = licenceHeldYearsQuestion.isVisible = hasConvictionsQuestion.isVisible = convictionsQuestion.isVisible = () => false
    }

    // Date of Birth
    dateOfBirthQuestion.maxDate = () => moment().add(-17, 'years').toDate()
    dateOfBirthQuestion.focusedDate = () => moment(dateOfBirthQuestion.maxDate()).subtract(1, 'years').toDate()

    return page
  }

  private setupProposer2Dependencies(page: Page, answers: IQuestionAnswer[]): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const hasAccessToAnotherVehicleQuestion = questions.find(q => q.name === QuestionEnum.hasAccessToAnotherVehicle) as SelectQuestion<string, string>
    const regularUseVehicleDetail = questions.find(q => q.name === QuestionEnum.regularUseVehicleDetail) as TextQuestion
    const isCompanyVehicleQuestion = questions.find(q => q.name === QuestionEnum.isCompanyVehicle) as SelectQuestion<string, string>
    isCompanyVehicleQuestion.isVisible = () => hasAccessToAnotherVehicleQuestion?.value === DLDCommonValuesEnum.Boolean.Yes
    regularUseVehicleDetail.isVisible = () => hasAccessToAnotherVehicleQuestion?.value === DLDCommonValuesEnum.Boolean.Yes

    this.setupPlaceholderDependencies(page, answers)

    return page
  }

  private setupProposer3Dependencies(page: Page, answers: IQuestionAnswer[]): Page {
    this.setupPlaceholderDependencies(page, answers)
    return page
  }

  private setupProposer4Dependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    // effective date
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate) as DateQuestion ?? questions.find(q => q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    effectiveDateQuestion.minDate = () => moment().toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()

    return page
  }

  private setupPlaceholderDependencies(page: Page, answers: IQuestionAnswer[]) {
    const firstName = (answers?.find(a => a.name === QuestionEnum.firstname) as QuestionAnswer<string>)?.value
    const lastName = (answers?.find(a => a.name === QuestionEnum.lastName) as QuestionAnswer<string>)?.value
    if (firstName && lastName)

      page.placeholderAnswers = { Firstname: firstName, Lastname: lastName }

    return page
  }

  private setupAdditionalDriverDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    // Self-Declared Convictions
    const hasAccessToAdditionalDriverLicence = questions.find(q => q.name === QuestionEnum.hasAccessToAdditionalDriverLicence) as SelectQuestion<string, string>
    const drivingLicenceTypeQuestion = questions.find(q => q.name === QuestionEnum.licenceType) as SelectQuestion<string, string>
    const licenceHeldYearsQuestion = questions.find(q => q.name === QuestionEnum.licenceHeldYears) as SelectQuestion<string, string>
    const hasConvictionsQuestion = questions.find(q => q.name === QuestionEnum.hasConvictions) as SelectQuestion<string, string>
    const convictionsQuestion = questions.find(q => q.name === QuestionEnum.convictions) as ConvictionsQuestion
    convictionsQuestion.minSubPages = 1

    if (hasAccessToAdditionalDriverLicence && hasAccessToAdditionalDriverLicence.visible) {
      drivingLicenceQuestion.isVisible = () => hasAccessToAdditionalDriverLicence.value === DLDCommonValuesEnum.Boolean.Yes
      drivingLicenceTypeQuestion.isVisible = licenceHeldYearsQuestion.isVisible = hasConvictionsQuestion.isVisible = () => hasAccessToAdditionalDriverLicence.value! === DLDCommonValuesEnum.Boolean.No
      convictionsQuestion.isVisible = () => hasAccessToAdditionalDriverLicence.value! === DLDCommonValuesEnum.Boolean.No && hasConvictionsQuestion?.value != null && hasConvictionsQuestion?.value === DLDCommonValuesEnum.Boolean.Yes
    }
    else {
      drivingLicenceTypeQuestion.isVisible = licenceHeldYearsQuestion.isVisible = hasConvictionsQuestion.isVisible = convictionsQuestion.isVisible = () => false
    }

    // Date of Birth
    dateOfBirthQuestion.maxDate = () => moment().add(-17, 'years').toDate()
    dateOfBirthQuestion.focusedDate = () => moment(dateOfBirthQuestion.maxDate()).subtract(1, 'years').toDate()

    // Regular Use Vehicle
    const adHasAccessToAnotherVehicleQuestion = questions.find(q => q.name === QuestionEnum.hasAccessToRegularVehicleUse) as SelectQuestion<string, string>
    const adRegularUseVehicleDetail = questions.find(q => q.name === QuestionEnum.regularUseVehicleDetail) as TextQuestion
    adRegularUseVehicleDetail.isVisible = () => adHasAccessToAnotherVehicleQuestion?.value === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  private setupAdditionalVehicleDependencies(page: Page, answers: IQuestionAnswer[], isMTA: boolean = false): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const vehicleTypeQuestion = questions.find(q => q.name === QuestionEnum.typeOfVehicle) as SelectQuestion<string, string>
    const riderWillCarryPillionQuestion = questions.find(q => q.name === QuestionEnum.riderWillCarryPillion) as SelectQuestion<string, string>
    riderWillCarryPillionQuestion.isVisible = () => vehicleTypeQuestion?.value != null && vehicleTypeQuestion?.value === DLDCommonValuesEnum.TypeOfVehicle.Motorbike

    const isModified = questions.find(q => q.name === QuestionEnum.vehicleIsModified) as SelectQuestion<string, string>
    const modificationsQuestion = questions.find(q => q.name === QuestionEnum.modifications) as ModificationQuestion
    modificationsQuestion.isVisible = () => isModified?.value != null && isModified?.value === DLDCommonValuesEnum.Boolean.Yes
    modificationsQuestion.minSubPages = 1
    modificationsQuestion.maxSubPages = 10

    const vehicleAddressQuestion = questions.find(q => q.name === QuestionEnum.vehicleAddress) as AddressQuestion
    const proposerAddressAnswer = answers.find(a => a.name === QuestionEnum.address) as QuestionAnswer<Address>
    vehicleAddressQuestion.value = vehicleAddressQuestion?.value != null ? vehicleAddressQuestion?.value : proposerAddressAnswer?.value

    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as SelectQuestion<string, string>

    if (isMTA) {
      setTimeout(() => {
        const selectedIndex = vehicleEstimatedMileage.options.findIndex(x => x.value === vehicleEstimatedMileage.value)

        if (selectedIndex >= 0)
          vehicleEstimatedMileage.options = vehicleEstimatedMileage.options.slice(selectedIndex)
      }, 100)
    }

    return page
  }

  private setupConvictionDependencies(page: Page, _answers: IQuestionAnswer[]): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const convictionDate = questions.find(q => q.name === QuestionEnum.convictionDate) as DateQuestion
    const hasConvictionban = questions.find(q => q.name === QuestionEnum.hasConvictionBan) as SelectQuestion<string, string>
    const banStartDate = questions.find(q => q.name === QuestionEnum.banStartDate) as DateQuestion
    const banEndDate = questions.find(q => q.name === QuestionEnum.banEndDate) as DateQuestion
    const convictionFine = questions.find(q => q.name === QuestionEnum.convictionFine) as NumberQuestion
    const convictionPointsReceived = questions.find(q => q.name === QuestionEnum.convictionPointsReceived) as NumberQuestion

    banStartDate.isVisible = banEndDate.isVisible = () => hasConvictionban?.value != null && hasConvictionban?.value === DLDCommonValuesEnum.Boolean.Yes

    if (convictionFine && convictionPointsReceived) {
      convictionFine.min = 0
      convictionPointsReceived.min = 0
    }

    if (convictionDate) {
      convictionDate.maxDate = () => moment().toDate()
      banStartDate.maxDate = () => moment().toDate()
      banEndDate.minDate = () => banStartDate?.value ?? null
      banEndDate.maxDate = () => moment().toDate()
    }

    return page
  }

  private mapConvictions(pageAnswers: Dictionary<PageAnswers>, pages: Page[], riskConvictions: VehicleConvictionModel[]) {
    const convictions = this.createAnswerForQuestion(pageAnswers, QuestionEnum.convictions, riskConvictions.length, pages)!
    convictions.forEach((c) => {
      for (let i = 0; i < riskConvictions.length; i += 1) {
        const conviction = riskConvictions[i]
        const convictionDate = new QuestionAnswer<Date>(0, QuestionEnum.convictionDate, '', conviction.convictionDate)
        const convictionType = new QuestionAnswer<string>(0, QuestionEnum.convictionType, '', conviction.convictionType.uniqueId)
        const convictionPersonsInjured = new QuestionAnswer<string>(0, QuestionEnum.convictionPersonsInjured, '', conviction.convictionPersonsInjured ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasConvictionBan = new QuestionAnswer<string>(0, QuestionEnum.hasConvictionBan, '', conviction.hasConvictionBan ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const banStartDate = new QuestionAnswer<Date>(0, QuestionEnum.banStartDate, '', conviction.banStartDate)
        const banEndDate = new QuestionAnswer<Date>(0, QuestionEnum.banEndDate, '', conviction.banEndDate)
        const convictionFine = new QuestionAnswer<number>(0, QuestionEnum.convictionFine, '', conviction.convictionFine)
        const convictionPointsReceived = new QuestionAnswer<number>(0, QuestionEnum.convictionPointsReceived, '', conviction.convictionPointsReceived)

        const convictionPage = new PageAnswers(`Conviction ${i + 1}`, [convictionDate, convictionType, convictionPersonsInjured, hasConvictionBan, banStartDate, banEndDate, convictionFine, convictionPointsReceived])
        c.subPageAnswers.push(convictionPage)
      }
    })
    return convictions
  }

  private mapModifications(pageAnswers: Dictionary<PageAnswers>, pages: Page[], vehicleModifications: VehicleModificationModel[]) {
    const modifications = this.createAnswerForQuestion(pageAnswers, QuestionEnum.modifications, vehicleModifications.length, pages)!
    modifications.forEach((m) => {
      for (let i = 0; i < vehicleModifications.length; i += 1) {
        const modification = vehicleModifications[i]
        const modificationSubCategory = new QuestionAnswer<string>(0, QuestionEnum.vehicleModificationSubCategory, '', modification.modificationSubCategory.uniqueId)
        const modificationDetail = new QuestionAnswer<string>(0, QuestionEnum.vehicleModifications, '', modification.modificationDetail.uniqueId)

        const modificationPage = new PageAnswers(`Modification ${i + 1}`, [modificationSubCategory, modificationDetail])
        m.subPageAnswers.push(modificationPage)
      }
    })
    return modifications
  }

  mapRiskToAnswers(risk: VehicleRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}

    this.mapAccountToAnswers(pageAnswers, account, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.autoRenew, account.autoRenew, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.customerHomePhoneNumber, account.mobilePhone, pages)

    // Proposer Details
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.policyholderType, risk.proposer.isCompany ? DLDCommonValuesEnum.PolicyholderType.Company : DLDCommonValuesEnum.PolicyholderType.Private, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.companyName, risk.proposer.companyName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasAccessToProposerLicence, risk.proposer.hasAccessToProposerLicence ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingLicence, risk.proposer.licenceNumber, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceType, risk.proposer.licenceType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.licenceHeldYears, risk.proposer.licenceHeldYears?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasConvictions, risk.proposer.hasConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    if (risk.proposer.convictions)
      this.mapConvictions(pageAnswers, pages, risk.proposer.convictions)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.referralSourceGroup, risk.businessSource?.dependencyId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.referralSource, risk.businessSource?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.effectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mtaEffectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.proposer.employmentStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.proposer.occupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.proposer.businessType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasAccessToRegularVehicleUse, risk.proposer.hasAccessToRegularVehicleUse! ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.regularUseVehicleDetail, risk.proposer.regularUseVehicleDetail!, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.isCompanyVehicle, risk.proposer.isCompanyVehicle! ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasAccessToAnotherVehicle, risk.proposer.hasAccessToAnotherVehicle! ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.clubName, risk.proposer.clubMembership.uniqueId!, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.currentInsurer, risk.proposer.currentInsurer?.uniqueId, pages)

    // Vehicle Details
    const addtionalVehicles = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalVehicle, risk.vehicles.length, pages)!
    addtionalVehicles.forEach((adv) => {
      for (let i = 0; i < risk.vehicles.length; i += 1) {
        const ad = risk.vehicles[i]

        const vehicle = new Vehicle()
        vehicle.registration = ad.registration
        vehicle.abiCode = ad.abiCode
        vehicle.vin = ad.vin
        vehicle.make = ad.make
        vehicle.model = ad.model
        vehicle.purchaseDate = ad.purchaseDate
        vehicle.yearOfManufacture = ad.yearOfMake
        vehicle.engineSize = ad.cubicCapacity

        const adVehicleType = new QuestionAnswer<string>(0, QuestionEnum.typeOfVehicle, '', ad.vehicleType.uniqueId)
        const adVehicleLookup = new QuestionAnswer<Vehicle>(0, QuestionEnum.vehicleLookup, '', vehicle)
        const adVehicleValue = new QuestionAnswer<number>(0, QuestionEnum.vehicleValue, '', ad.value)
        const adRiderWillCarryPillion = new QuestionAnswer<string>(0, QuestionEnum.riderWillCarryPillion, '', ad.riderWillCarryPillion! ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adAnnualMileage = new QuestionAnswer<string>(0, QuestionEnum.vehicleEstimatedMileage, '', ad.annualMileage.uniqueId)
        const adIsModified = new QuestionAnswer<string>(0, QuestionEnum.vehicleIsModified, '', ad.isModified ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)

        const adModifications = new QuestionAnswer<number>(0, QuestionEnum.modifications, '', ad.modifications?.length)
        const modificationsAnswers: Dictionary<PageAnswers> = {}
        const modifications = ad.modifications ? this.mapModifications(modificationsAnswers, pages, ad.modifications) : []
        adModifications.subPageAnswers = modifications.length > 0 ? (modifications[0] as QuestionAnswer<number>).subPageAnswers : []

        const adIsOwnedByProposer = new QuestionAnswer<string>(0, QuestionEnum.vehicleOwner, '', ad.isOwnedByProposer.uniqueId === DLDCommonValuesEnum.RegisteredKeeper.ProposerPolicyHolder ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adLengthOfOwnership = new QuestionAnswer<string>(0, QuestionEnum.ownerVehicleYears, '', ad.lengthOfOwnership.uniqueId)
        const adClassOfUse = new QuestionAnswer<string>(0, QuestionEnum.classOfUse, '', ad.classOfUse.uniqueId)
        const adIsPrivatelyStored = new QuestionAnswer<string>(0, QuestionEnum.isPrivatelyStored, '', ad.isPrivatelyStored ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adVehicleStorage = new QuestionAnswer<string>(0, QuestionEnum.vehicleStorage, '', ad.vehicleStorage?.uniqueId)
        const adVoluntaryExcess = new QuestionAnswer<string>(0, QuestionEnum.voluntaryExcess, '', ad.voluntaryExcess?.uniqueId)
        const adVehicleSecurityDevice = new QuestionAnswer<string>(0, QuestionEnum.vehicleSecurityDevice, '', ad.securityDevice?.uniqueId)
        const adEuropeanCover = new QuestionAnswer<string>(0, QuestionEnum.europeanCover, '', ad.europeanCover?.uniqueId)
        const adVehicleAddress = new QuestionAnswer<Address>(0, QuestionEnum.vehicleAddress, '', ad.address)
        const adVehicleCondition = new QuestionAnswer<string>(0, QuestionEnum.vehicleCondition, '', ad.vehicleCondition?.uniqueId)

        const adPage = new PageAnswers(`Additional Vehicle ${i + 1}`, [adVehicleType, adVehicleLookup, adVehicleValue, adRiderWillCarryPillion, adAnnualMileage, adIsModified, adModifications, adIsOwnedByProposer, adLengthOfOwnership, adClassOfUse, adIsPrivatelyStored, adVehicleStorage, adVoluntaryExcess, adVehicleSecurityDevice, adEuropeanCover, adVehicleAddress, adVehicleCondition])
        adv.subPageAnswers.push(adPage)
      }
    })

    // Additional Driver Details
    const additionalDrivers = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDriver, risk.vehicleDrivers.length, pages)!
    additionalDrivers.forEach((ada) => {
      for (let i = 0; i < risk.vehicleDrivers.length; i += 1) {
        const ad = risk.vehicleDrivers[i]
        const adTitle = new QuestionAnswer<string>(0, QuestionEnum.title, '', ad.title.uniqueId)
        const adFirstName = new QuestionAnswer<string>(0, QuestionEnum.firstname, '', ad.firstName)
        const adLastName = new QuestionAnswer<string>(0, QuestionEnum.lastName, '', ad.lastName)
        const adDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', ad.dateOfBirth)
        const adLicenceNumber = new QuestionAnswer<string>(0, QuestionEnum.drivingLicence, '', ad.licenceNumber)
        const adHasAccessToRegularVehicleUse = new QuestionAnswer<string>(0, QuestionEnum.hasAccessToRegularVehicleUse, '', ad.hasAccessToRegularVehicleUse ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adRegularUseVehicleDetail = new QuestionAnswer<string>(0, QuestionEnum.regularUseVehicleDetail, '', ad.regularUseVehicleDetail!)
        const adRelationshipToPolicyholder = new QuestionAnswer<string>(0, QuestionEnum.relationshipToPolicyHolder, '', ad.relationshipToPolicyHolder?.uniqueId)
        const adHasAccessToAnotherVehicle = new QuestionAnswer<string>(0, QuestionEnum.hasAccessToAnotherVehicle, '', ad.hasAccessToAnotherVehicle ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adDriverToVehicles = new QuestionAnswer<string[]>(0, QuestionEnum.driverToVehicles, '', ad.driverToVehicles)

        const adHasAccessToAdditionalDriverLicence = new QuestionAnswer<string>(0, QuestionEnum.hasAccessToAdditionalDriverLicence, '', ad.proposerHasAccessToAdditionalDriverLicence ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adLicenceType = new QuestionAnswer<string>(0, QuestionEnum.licenceType, '', ad.licenceType?.uniqueId)
        const adLicenceHeldYears = new QuestionAnswer<string>(0, QuestionEnum.licenceHeldYears, '', ad.licenceHeldYears?.uniqueId)
        const adHasConvictions = new QuestionAnswer<string>(0, QuestionEnum.hasConvictions, '', ad.hasConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)

        const adConvictions = new QuestionAnswer<number>(0, QuestionEnum.convictions, '', ad.convictions?.length)
        const convictionsAnswers: Dictionary<PageAnswers> = {}
        const convictions = ad.convictions ? this.mapConvictions(convictionsAnswers, pages, ad.convictions) : []
        adConvictions.subPageAnswers = convictions.length > 0 ? (convictions[0] as QuestionAnswer<number>).subPageAnswers : []

        const adPage = new PageAnswers(`Additional Driver ${i + 1}`, [adTitle, adFirstName, adLastName, adDateOfBirth, adLicenceNumber, adHasAccessToRegularVehicleUse, adRelationshipToPolicyholder, adHasAccessToAnotherVehicle, adDriverToVehicles, adRegularUseVehicleDetail, adHasAccessToAdditionalDriverLicence, adLicenceType, adLicenceHeldYears, adHasConvictions, adConvictions])
        ada.subPageAnswers.push(adPage)
      }
    })

    return pageAnswers
  }
}
