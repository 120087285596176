import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IAddressService from './interfaces/IAddressService'
import Address from './models/Address'

export default class AddressService implements IAddressService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
  }

  async getAddress(postcode: string, houseNumber?: string): Promise<Address[]> {
    let result = null
    if (!houseNumber)
      result = await this.axios.get(`${this.apiUrl}/address/${encodeURIComponent(postcode)}`, { headers: { can500Error: true } })
    else
      result = await this.axios.get(`${this.apiUrl}/address/${encodeURIComponent(postcode)}`, { params: { houseNumber }, headers: { can500Error: true } })

    return this.jsonConvert.deserializeArray(result.data, Address)
  }
}
