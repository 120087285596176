import moment from 'moment'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type SchemeRequest from '../values/SchemeRequest'
import type QuoteRequest from '../values/QuoteRequest'
import type Page from '../../view-models/Page'
import type ProductPage from '../models/ProductPage'
import QuestionEnum from '../enums/QuestionEnum'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import type { AdditionalDriver } from '../values/Driver'
import { CarProposer } from '../values/Driver'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import Vehicle from '../values/Vehicle'
import AccountRegister from '../values/AccountRegister'
import { CarRisk, PropertyRisk } from '../models/Risk'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import Car from '../values/Car'
import type IPageMappings from '../interfaces/IPageMappings'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type Address from '../models/Address'
import CarQuoteRequest, { CarMTAQuoteRequest } from '../values/CarQuoteRequest'
import type IQuestion from '../interfaces/IQuestion'
import type Risk from '../models/Risk'
import Property from '../values/Property/Property'
import PropertyQuoteRequest, { PropertyMTAQuoteRequest } from '../values/Property/PropertyQuoteRequest'
import RiskProposer from '../values/RiskProposer'
import type SpecifiedItem from '../values/Property/SpecifiedItem'
import AdditionalPolicyholder from '../values/AdditionalPolicyholder'
import type PropertyClaim from '../values/Property/PropertyClaim'
import BaseMapper from './BaseMapper'
import type { ClaimsQuestion, DateQuestion, DrivingLicenceQuestion, NumberQuestion, RelatedSelectQuestion, SelectQuestion, SpecifiedItemsQuestion, TextQuestion } from '@/view-models/Question'
import ContextLevel from '@/services/enums/ContextLevel'
import type { Dictionary } from '@/types'
import Routes from '@/constants/Routes'
import useAnswersStore from '@/stores/answers-store'

export default class YogaMapper extends BaseMapper implements IQuestionMapper, IRequestMapper, IResponseMapper, IPageMappings {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_Proposer_CustomerFacing: { route: Routes.scheme, contextLevel: ContextLevel.preQuote },
    ModernCar_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    ModernCar_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    ModernCar_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    ModernCar_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    ModernCar_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
    ModernCar_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    CommercialVehicle_Proposer_CustomerFacing: { route: Routes.scheme, contextLevel: ContextLevel.preQuote },
    CommercialVehicle_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    CommercialVehicle_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    CommercialVehicle_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    CommercialVehicle_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    CommercialVehicle_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
    CommercialVehicle_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    Home_Your_Home_CustomerFacing: { route: Routes.scheme, contextLevel: ContextLevel.preQuote },
    Home_Contents_Occupancy_And_Security_CustomerFacing: { route: Routes.homeContentsOccupancyAndSecurity, contextLevel: ContextLevel.preQuote },
    Home_Proposer_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    Home_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    Home_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    Home_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
    Home_VAPs_CustomerFacing: { route: Routes.additionalProducts, contextLevel: ContextLevel.quote },
    Home_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.policy },
    Home_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_ProposerMta_CustomerFacing: { route: Routes.mtaScheme, contextLevel: ContextLevel.mtaPreQuote },
    ModernCar_PremiumMta: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    ModernCar_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    ModernCar_PaymentConfirmationMta: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
    CommercialVehicle_ProposerMta_CustomerFacing: { route: Routes.mtaScheme, contextLevel: ContextLevel.mtaPreQuote },
    CommercialVehicle_PremiumMta: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    CommercialVehicle_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    CommercialVehicle_PaymentConfirmationMta: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
    Home_Your_HomeMTA_CustomerFacing: { route: Routes.mtaScheme, contextLevel: ContextLevel.mtaPreQuote },
    Home_Contents_Occupancy_And_SecurityMTA_CustomerFacing: { route: Routes.mtahomeContentsOccupancyAndSecurity, contextLevel: ContextLevel.mtaPreQuote },
    Home_ProposerMTA_CustomerFacing: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    Home_PremiumMTA_CustomerFacing: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    Home_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    Home_PaymentConfirmationMta: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    ModernCar_ProposerRenewal_CustomerFacing: { route: Routes.renewalScheme, contextLevel: ContextLevel.renewalPreQuote },
    ModernCar_PremiumRenewal: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    ModernCar_PaymentRenewal: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    ModernCar_PostTakeUpVAPs_CustomerFacing: { route: Routes.renewalUpgrade, contextLevel: ContextLevel.renewalTakenUp },
    ModernCar_VAPs_Payment_CustomerFacing: { route: Routes.renewalUpgradePayment, contextLevel: ContextLevel.renewalTakenUp },
    ModernCar_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
    CommercialVehicle_ProposerRenewal_CustomerFacing: { route: Routes.renewalScheme, contextLevel: ContextLevel.renewalPreQuote },
    CommercialVehicle_PremiumRenewal: { route: Routes.renewalPremium, contextLevel: ContextLevel.renewalQuote },
    CommercialVehicle_PaymentRenewal: { route: Routes.renewalPayment, contextLevel: ContextLevel.renewalQuote },
    CommercialVehicle_PostTakeUpVAPs_CustomerFacing: { route: Routes.upgrade, contextLevel: ContextLevel.renewalTakenUp },
    CommercialVehicle_VAPs_Payment_CustomerFacing: { route: Routes.upgradePayment, contextLevel: ContextLevel.renewalTakenUp },
    CommercialVehicle_PaymentConfirmation: { route: Routes.renewalPaymentConfirmation, contextLevel: ContextLevel.renewalTakenUp },
  }

  setupPage(setId: string, productPage: ProductPage): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage)
  }

  setupDependencies(setId: string, mappedPage: Page): Page {
    switch (setId) {
      case 'ModernCar_Proposer_CustomerFacing':
      case 'ModernCar_ProposerMta_CustomerFacing':
      case 'ModernCar_ProposerRenewal_CustomerFacing':
        return this.setupProposerDependencies(mappedPage)
      case 'CommercialVehicle_Proposer_CustomerFacing':
      case 'CommercialVehicle_ProposerMta_CustomerFacing':
      case 'CommercialVehicle_ProposerRenewal_CustomerFacing':
        return this.setupProposerVanDependencies(mappedPage)
      case 'ModernCar_Proposer_CustomerFacing_AdditionalDriver':
      case 'ModernCar_ProposerMta_CustomerFacing_AdditionalDriver':
      case 'ModernCar_ProposerRenewal_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_Proposer_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_ProposerMta_CustomerFacing_AdditionalDriver':
      case 'CommercialVehicle_ProposerRenewal_CustomerFacing_AdditionalDriver':
        return this.setupAdditionalDriverDependencies(mappedPage)
      case 'ModernCar_AggregatorLanding':
      case 'ModernCar_AggregatorLanding_AdditionalDriver':
      case 'CommercialVehicle_AggregatorLanding':
      case 'CommercialVehicle_AggregatorLanding_AdditionalDriver':
        return this.setupAggregatorLandingDependencies(mappedPage)
      case 'Home_Your_Home_CustomerFacing':
      case 'Home_Your_HomeMTA_CustomerFacing':
      case 'Home_Contents_Occupancy_And_Security_CustomerFacing':
      case 'Home_Contents_Occupancy_And_SecurityMTA_CustomerFacing':
        return this.setupPropertyDependencies(mappedPage)
      case 'Home_Specified_Items_CustomerFacing':
      case 'Home_Specified_ItemsMTA_CustomerFacing':
        return this.setupSpecifiedItems(mappedPage)
      case 'Home_Proposer_CustomerFacing':
      case 'Home_ProposerMTA_CustomerFacing':
        return this.setupHomeProposerDependencies(mappedPage)
      case 'Home_Proposer_CustomerFacing_AdditionalProposer':
      case 'Home_ProposerMTA_CustomerFacing_AdditionalProposer':
        return this.setupHomeAdditionalProposerDependencies(mappedPage)
      case 'Home_Claims_CustomerFacing':
      case 'Home_ClaimsMTA_CustomerFacing':
      case 'ModernCar_VAPs_CustomerFacing':
      case 'ModernCar_PostTakeUpVAPs_CustomerFacing':
      case 'ModernCar_VAPs_Payment_CustomerFacing':
      case 'ModernCar_Premium_CustomerFacing':
      case 'ModernCar_PremiumMta':
      case 'ModernCar_Payment':
      case 'ModernCar_PaymentConfirmation':
      case 'ModernCar_PaymentConfirmationMta':
      case 'ModernCar_PremiumRenewal':
      case 'ModernCar_PaymentRenewal':
      case 'CommercialVehicle_VAPs_CustomerFacing':
      case 'CommercialVehicle_PostTakeUpVAPs_CustomerFacing':
      case 'CommercialVehicle_VAPs_Payment_CustomerFacing':
      case 'CommercialVehicle_Premium_CustomerFacing':
      case 'CommercialVehicle_PremiumMta':
      case 'CommercialVehicle_Payment':
      case 'CommercialVehicle_PaymentConfirmation':
      case 'CommercialVehicle_PaymentConfirmationMta':
      case 'CommercialVehicle_PremiumRenewal':
      case 'CommercialVehicle_PaymentRenewal':
      case 'Home_Payment':
      case 'Home_Premium_CustomerFacing':
      case 'Home_PremiumMTA_CustomerFacing':
      case 'Home_PaymentConfirmation':
      case 'Home_VAPs_CustomerFacing':
      case 'Home_PostTakeUpVAPs_CustomerFacing':
      case 'Home_VAPs_Payment_CustomerFacing':
      case 'Home_PaymentConfirmationMta':
      case 'Account':
      case 'Home_AggregatorLanding':
        return mappedPage
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setupPropertyDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const homeOwnedOrRentedQuestion = questions.find(q => q.name === QuestionEnum.homeOwnedOrRented) as SelectQuestion<string, string>
    const buildingsContentsOrBothQuestion = questions.find(q => q.name === QuestionEnum.buildingsContentsOrBoth) as SelectQuestion<string, string>
    const propertyTypeQuestion = questions.find(q => q.name === QuestionEnum.propertyType) as SelectQuestion<string, string>
    const homeTypeQuestion = questions.find(q => q.name === QuestionEnum.homeType) as RelatedSelectQuestion
    const costToRebuildPropertyQuestion = questions.find(q => q.name === QuestionEnum.costToRebuildProperty) as NumberQuestion
    const hasHighValueItems = questions.find(q => q.name === QuestionEnum.hasHighValueItems) as SelectQuestion<string, string>
    const anyUnspecifiedItemsQuestion = questions.find(q => q.name === QuestionEnum.anyUnspecifiedItems) as SelectQuestion<string, string>
    const unspecifiedItemValueQuestion = questions.find(q => q.name === QuestionEnum.unspecifiedItemValue) as TextQuestion
    const replaceEntireContentsQuestion = questions.find(q => q.name === QuestionEnum.costToReplaceContents) as TextQuestion
    const hasPurchasedPropertyQuestion = questions.find(q => q.name === QuestionEnum.hasPurchasedProperty) as SelectQuestion<string, string>
    const dateOfPurchaseQuestion = questions.find(q => q.name === QuestionEnum.dateOfPurchase) as DateQuestion
    const whoLivesAtPropertyQuestion = questions.find(q => q.name === QuestionEnum.whoLivesAtProperty) as SelectQuestion<string, string>
    const howManyAdultsQuestion = questions.find(q => q.name === QuestionEnum.howManyAdults) as SelectQuestion<string, string>
    const howManyChildrenQuestion = questions.find(q => q.name === QuestionEnum.howManyChildren) as SelectQuestion<string, string>
    const approximateBuildYearQuestion = questions.find(q => q.name === QuestionEnum.approximateBuildYear) as NumberQuestion
    const howLongHaveYouLivedAtThePropertyQuestion = questions.find(q => q.name === QuestionEnum.howLongHaveYouLivedAtTheProperty) as SelectQuestion<string, string>
    const specifiedItemsQuestion = questions.find(q => q.name === QuestionEnum.specifiedItemsSubpage) as SpecifiedItemsQuestion
    const alarmProfessionallyInstalledMaintainedQuestion = questions.find(q => q.name === QuestionEnum.alarmProfessionallyInstalledMaintained) as SelectQuestion<string, string>
    const typeOfAlarmQuestion = questions.find(q => q.name === QuestionEnum.typeOfAlarm) as SelectQuestion<string, string>

    if (approximateBuildYearQuestion)
      approximateBuildYearQuestion.max = moment().toDate().getFullYear()

    if (homeTypeQuestion && propertyTypeQuestion) {
      homeTypeQuestion.isVisible = () => propertyTypeQuestion.value !== null
      homeTypeQuestion.dependentQuestions = [propertyTypeQuestion]
    }

    if (typeOfAlarmQuestion && alarmProfessionallyInstalledMaintainedQuestion) {
      alarmProfessionallyInstalledMaintainedQuestion.isVisible = () => typeOfAlarmQuestion.value != null
      && typeOfAlarmQuestion.value !== DLDCommonValuesEnum.PropertyInsuranceTypeOfAlarm.NoAlarm
    }

    if (buildingsContentsOrBothQuestion && homeOwnedOrRentedQuestion) {
      buildingsContentsOrBothQuestion.isVisible = () => homeOwnedOrRentedQuestion.value != null
      && (homeOwnedOrRentedQuestion.value === DLDCommonValuesEnum.PropertyInsuranceOwnerhip.OwnedOutright
      || homeOwnedOrRentedQuestion.value === DLDCommonValuesEnum.PropertyInsuranceOwnerhip.Mortgaged)

      homeOwnedOrRentedQuestion.onInputChanged = () => {
        if (homeOwnedOrRentedQuestion.value !== DLDCommonValuesEnum.PropertyInsuranceOwnerhip.OwnedOutright
          && homeOwnedOrRentedQuestion.value !== DLDCommonValuesEnum.PropertyInsuranceOwnerhip.Mortgaged)
          buildingsContentsOrBothQuestion.value = DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents
      }
    }

    if (buildingsContentsOrBothQuestion && costToRebuildPropertyQuestion) {
      costToRebuildPropertyQuestion.isVisible = () => buildingsContentsOrBothQuestion.value != null
      && buildingsContentsOrBothQuestion.value !== DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents
    }

    if (hasHighValueItems)
      hasHighValueItems.isVisible = () => this.IncludesContents()

    if (specifiedItemsQuestion)
      specifiedItemsQuestion.isVisible = () => this.IncludesContents() && hasHighValueItems.value === DLDCommonValuesEnum.Boolean.Yes

    if (anyUnspecifiedItemsQuestion)
      anyUnspecifiedItemsQuestion.isVisible = () => this.IncludesContents()

    if (anyUnspecifiedItemsQuestion)
      unspecifiedItemValueQuestion.isVisible = () => anyUnspecifiedItemsQuestion.value === DLDCommonValuesEnum.Boolean.Yes && this.IncludesContents()

    if (replaceEntireContentsQuestion)
      replaceEntireContentsQuestion.isVisible = () => this.IncludesContents()

    if (hasPurchasedPropertyQuestion) {
      dateOfPurchaseQuestion.isVisible = () => hasPurchasedPropertyQuestion.value === DLDCommonValuesEnum.Boolean.Yes
      howLongHaveYouLivedAtThePropertyQuestion.isVisible = () => hasPurchasedPropertyQuestion.value === DLDCommonValuesEnum.Boolean.No
    }

    if (whoLivesAtPropertyQuestion) {
      howManyAdultsQuestion.isVisible = () => whoLivesAtPropertyQuestion.value !== null && whoLivesAtPropertyQuestion.value !== DLDCommonValuesEnum.PropertyInsuranceWhoLivesAtProperty.PolicyholderOnly
      howManyChildrenQuestion.isVisible = () => whoLivesAtPropertyQuestion.value !== null && whoLivesAtPropertyQuestion.value !== DLDCommonValuesEnum.PropertyInsuranceWhoLivesAtProperty.PolicyholderOnly
    }

    return page
  }

  private IncludesContents(): boolean {
    const answersStore = useAnswersStore()
    const buildingsContentsOrBothQuestion = answersStore.answers.find(q => q.name === QuestionEnum.buildingsContentsOrBoth) as QuestionAnswer<string>

    return buildingsContentsOrBothQuestion && (buildingsContentsOrBothQuestion.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents
      || buildingsContentsOrBothQuestion.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents)
  };

  private setupAggregatorLandingDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    return page
  }

  private setupAdditionalDriverDependencies(page: Page): Page {
    this.setupAddDriverDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)
    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const relationshipToPolicyHolder = questions.find(q => q.name === QuestionEnum.relationshipToPolicyHolder)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]
    dateOfBirthQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    relationshipToPolicyHolder!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
    employmentStatusQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    occupationQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    businessCategoryQuestion!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion
    if (residentSinceQuestion)
      residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted

    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as SelectQuestion<string, string>
    const driveOtherVehicles = questions.find(q => q.name === QuestionEnum.doYouDriveAnyVehicleBesidesThisOne) as SelectQuestion<string, string>
    const otherVehiclesYouDrive = questions.find(q => q.name === QuestionEnum.otherVehiclesYouDrive) as SelectQuestion<string, string>

    if (maritalStatus)
      maritalStatus!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]

    if (driveOtherVehicles)
      driveOtherVehicles!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]

    if (otherVehiclesYouDrive) {
      otherVehiclesYouDrive!.dependentQuestions = [firstNameQuestion!, lastNameQuestion!]
      otherVehiclesYouDrive.isVisible = () => driveOtherVehicles.value !== null && driveOtherVehicles.value === DLDCommonValuesEnum.Boolean.Yes
    }

    return page
  }

  private setupHomeProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)

    const answersStore = useAnswersStore()
    const buildingsContentsOrBothQuestion = answersStore.answers.find(a => a.name === QuestionEnum.buildingsContentsOrBoth) as QuestionAnswer<string>
    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const address = questions.find(q => q.name === QuestionEnum.proposerAddress) as SelectQuestion<string, string>
    const sameAsHomeAddress = questions.find(q => q.name === QuestionEnum.sameAsHomeAddress) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    const yearsWithNoClaimsBuilding = questions.find(q => q.name === QuestionEnum.yearsWithNoClaimsBuilding) as SelectQuestion<string, string>
    const yearsWithNoClaimsContents = questions.find(q => q.name === QuestionEnum.yearsWithNoClaimsContents) as SelectQuestion<string, string>
    const buildingsVoluntaryExcess = questions.find(q => q.name === QuestionEnum.buildingsVoluntaryExcess) as SelectQuestion<string, string>
    const contentsVoluntaryExcess = questions.find(q => q.name === QuestionEnum.contentsVoluntaryExcess) as SelectQuestion<string, string>
    const coverStartDate = questions.find(q => q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    const includeAccidentalDamageForBuildings = questions.find(q => q.name === QuestionEnum.includeAccidentalDamageForBuildings) as SelectQuestion<string, string>
    const includeAccidentalDamageForContents = questions.find(q => q.name === QuestionEnum.includeAccidentalDamageForContents) as SelectQuestion<string, string>

    coverStartDate.maxDate = () => new Date()

    coverStartDate.minDate = () => moment().toDate()
    coverStartDate.maxDate = () => moment().add(31, 'days').toDate()

    occupationQuestion.options = occupationQuestion.options.filter(o => ![
      DLDCommonValuesEnum.Occupation.Unemployed,
      DLDCommonValuesEnum.Occupation.NotInEmployment,
      DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability,
    ].find(x => x === o.value))
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
    businessCategoryQuestion.options = businessCategoryQuestion.options.filter(b => ![
      DLDCommonValuesEnum.BusinessCategory.NotInEmployment,
      DLDCommonValuesEnum.BusinessCategory.NonUnemployed,
      DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability,
    ].find(x => x === b.value))

    occupationQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation

    const studentTypeQuestion = questions.find(q => q.name === QuestionEnum.studentType) as SelectQuestion<string, string>

    studentTypeQuestion.isVisible = () => (employmentStatusQuestion.value != null
    && employmentStatusQuestion.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) || !!occupationQuestion.value?.includes('Student')

    businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation && !studentTypeQuestion.isVisible()

    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as SelectQuestion<string, string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as SelectQuestion<string, string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as SelectQuestion<string, string>
    if (secondaryOccupation && secondaryBusinessCategory)
      secondaryOccupation.isVisible = secondaryBusinessCategory.isVisible = () => hasSecondaryEmployment.value != null && hasSecondaryEmployment.value === DLDCommonValuesEnum.Boolean.Yes

    address.isVisible = () => sameAsHomeAddress?.value === DLDCommonValuesEnum.Boolean.No
    yearsWithNoClaimsBuilding.isVisible = buildingsVoluntaryExcess.isVisible = () => buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents || buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Buildings
    yearsWithNoClaimsContents.isVisible = contentsVoluntaryExcess.isVisible = () => buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents || buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents

    includeAccidentalDamageForBuildings.isVisible = () => buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents || buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Buildings
    includeAccidentalDamageForContents.isVisible = () => buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents || buildingsContentsOrBothQuestion?.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents

    const hasClaims = questions.find(a => a.name === QuestionEnum.hasClaims) as SelectQuestion<string, string>
    const claims = questions.find(q => q.name === QuestionEnum.claims) as ClaimsQuestion

    claims.isVisible = claims.isShowSubPageOnLoad = () => hasClaims?.value === DLDCommonValuesEnum.Boolean.Yes
    return page
  }

  private setupHomeAdditionalProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)
    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    occupationQuestion.options = occupationQuestion.options.filter(o => ![
      DLDCommonValuesEnum.Occupation.Unemployed,
      DLDCommonValuesEnum.Occupation.NotInEmployment,
      DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability,
    ].find(x => x === o.value))
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
    businessCategoryQuestion.options = businessCategoryQuestion.options.filter(b => ![
      DLDCommonValuesEnum.BusinessCategory.NotInEmployment,
      DLDCommonValuesEnum.BusinessCategory.NonUnemployed,
      DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability,
    ].find(x => x === b.value))

    occupationQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation

    const studentTypeQuestion = questions.find(q => q.name === QuestionEnum.studentType) as SelectQuestion<string, string>

    studentTypeQuestion.isVisible = () => (employmentStatusQuestion.value != null
    && employmentStatusQuestion.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) || !!occupationQuestion.value?.includes('Student')

    businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation && !studentTypeQuestion.isVisible()

    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as SelectQuestion<string, string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as SelectQuestion<string, string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as SelectQuestion<string, string>

    if (secondaryOccupation && secondaryBusinessCategory)
      secondaryOccupation.isVisible = secondaryBusinessCategory.isVisible = () => hasSecondaryEmployment.value != null && hasSecondaryEmployment.value === DLDCommonValuesEnum.Boolean.Yes

    return page
  }

  protected setupAddDriverDependencies(page: Page): void {
    this.setupCommonDependencies(page)
    const questions = page.sections.flatMap(s => s.questions)
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion
    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    if (residentSinceQuestion) {
      residentSinceQuestion.minDate = () => this.setupDOB(questions).value
      residentSinceQuestion.maxDate = () => moment().toDate()
      residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No
    }
  }

  protected setupSpecifiedItems(page: Page): Page {
    this.setupCommonDependencies(page)
    return page
  }

  private setupProposerVanDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const residentSinceQuestion = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion
    const residentSinceBirthQuestion = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>

    residentSinceQuestion.minDate = () => this.setupDOB(questions).value
    residentSinceQuestion.maxDate = () => moment().toDate()
    residentSinceQuestion.isVisible = () => residentSinceBirthQuestion.value! === DLDCommonValuesEnum.Boolean.No
    return this.setupProposerDependencies(page)
  }

  private setupProposerDependencies(page: Page): Page {
    this.setupCommonDependencies(page)

    const questions = page.sections.flatMap(s => s.questions)
    const classOfUse = questions.find(q => q.name === QuestionEnum.classOfUse || q.name === QuestionEnum.commercialVehicleClassOfUse) as SelectQuestion<string, string>
    const vehicleBusinessMileage = questions.find(q => q.name === QuestionEnum.vehicleBusinessMileage) as NumberQuestion
    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as NumberQuestion

    vehicleBusinessMileage.isVisible = (): boolean => classOfUse.value !== null && classOfUse.value !== DLDCommonValuesEnum.ClassOfUse.SDPOnly && classOfUse.value !== DLDCommonValuesEnum.ClassOfUse.SDPCommuting && classOfUse.value !== DLDCommonValuesEnum.CommercialClassOfUse.SocialDomesticPleasureAndCommuting && classOfUse.value !== DLDCommonValuesEnum.CommercialClassOfUse.SocialDomesticPleasureOnly

    const trackerType = questions.find(q => q.name === QuestionEnum.trackerType) as SelectQuestion<string, string>
    const carTrackerModel = questions.find(q => q.name === QuestionEnum.trackerModel) as SelectQuestion<string, string>

    if (carTrackerModel)
      carTrackerModel.isVisible = () => trackerType.value !== null && trackerType.value !== 'CarTrackerType.None'

    const alarmType = questions.find(q => q.name === QuestionEnum.modernCarAlarmType) as SelectQuestion<string, string>
    const alarmModel = questions.find(q => q.name === QuestionEnum.alarmModel) as SelectQuestion<string, string>
    const alarmFittedBy = questions.find(q => q.name === QuestionEnum.alarmFittedBy) as SelectQuestion<string, string>

    if (alarmModel && alarmFittedBy)
      alarmModel.isVisible = alarmFittedBy.isVisible = () => alarmType.value !== null && alarmType.value !== 'ModernCarAlarmType.None'

    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate) as DateQuestion ?? questions.find(q => q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion

    const employmentStatusQuestion = questions.find(q => q.name === QuestionEnum.employmentStatus) as SelectQuestion<string, string>
    const occupationQuestion = questions.find(q => q.name === QuestionEnum.occupation) as SelectQuestion<string, string>
    occupationQuestion.options = occupationQuestion.options.filter(o => ![
      DLDCommonValuesEnum.Occupation.Unemployed,
      DLDCommonValuesEnum.Occupation.NotInEmployment,
      DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability,
    ].find(x => x === o.value))
    const businessCategoryQuestion = questions.find(q => q.name === QuestionEnum.businessCategory) as SelectQuestion<string, string>
    businessCategoryQuestion.options = businessCategoryQuestion.options.filter(b => ![
      DLDCommonValuesEnum.BusinessCategory.NotInEmployment,
      DLDCommonValuesEnum.BusinessCategory.NonUnemployed,
      DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability,
    ].find(x => x === b.value))

    occupationQuestion.isVisible = businessCategoryQuestion.isVisible = () => employmentStatusQuestion.value != null
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Retired
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.Unemployed
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.IndependentMeans
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties
    && employmentStatusQuestion.value !== DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted

    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as SelectQuestion<string, string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as SelectQuestion<string, string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as SelectQuestion<string, string>

    if (secondaryOccupation && secondaryBusinessCategory)
      secondaryOccupation.isVisible = secondaryBusinessCategory.isVisible = () => hasSecondaryEmployment.value != null && hasSecondaryEmployment.value === DLDCommonValuesEnum.Boolean.Yes

    const doYouDriveAnyOtherVehicles = questions.find(q => q.name === QuestionEnum.doYouDriveAnyVehicleBesidesThisOne) as SelectQuestion<string, string>
    const selectOtherVehiclesDriven = questions.find(q => q.name === QuestionEnum.otherVehiclesYouDrive) as SelectQuestion<string, string>

    if (selectOtherVehiclesDriven)
      selectOtherVehiclesDriven.isVisible = () => doYouDriveAnyOtherVehicles.value !== null && doYouDriveAnyOtherVehicles.value === DLDCommonValuesEnum.Boolean.Yes

    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as SelectQuestion<string, string>
    const residentSince = questions.find(q => q.name === QuestionEnum.residentSince) as DateQuestion

    residentSince.isVisible = () => residentSinceBirth.value !== null && residentSinceBirth.value === DLDCommonValuesEnum.Boolean.No

    effectiveDateQuestion.maxDate = () => new Date()

    effectiveDateQuestion.minDate = () => moment().toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()
    vehicleBusinessMileage.min = 1
    vehicleEstimatedMileage.min = 1

    const titleQuestion = questions.find(q => q.name === QuestionEnum.title)
    const firstNameQuestion = questions.find(q => q.name === QuestionEnum.firstname)
    const lastNameQuestion = questions.find(q => q.name === QuestionEnum.lastName)
    const dateOfBirthQuestion = questions.find(q => q.name === QuestionEnum.dateOfBirth)
    const drivingLicenceQuestion = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion

    drivingLicenceQuestion.dependentQuestions = [titleQuestion!, firstNameQuestion!, lastNameQuestion!, dateOfBirthQuestion!]

    return page
  }

  protected setupDOB(questions: IQuestion[]): DateQuestion {
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-17, 'years').toDate()
    dateOfBirth.focusedDate = () => moment(dateOfBirth.maxDate()).subtract(1, 'years').toDate()
    return dateOfBirth
  }

  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented. Yoga does not use Scheme Requests.')
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. Wrapper does not use custom validation.')
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const quoteRequest = new Type()

    if ((quoteRequest instanceof CarQuoteRequest || quoteRequest instanceof CarMTAQuoteRequest))
      return this.getCarQuoteRequest(questions, Type)
    else if (quoteRequest instanceof PropertyQuoteRequest)
      return this.getPropertyQuoteRequest(questions, Type)

    throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Quotax.`)
  }

  getCarQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new() => T): T {
    // Vehicle details
    const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as QuestionAnswer<Vehicle>
    const vehiclePurchaseDate = questions.find(q => q.name === QuestionEnum.dateOfPurchase) as QuestionAnswer<Date>
    const vehicleValue = questions.find(q => q.name === QuestionEnum.customerVehicleValue) as QuestionAnswer<number>
    const vehicleEstimatedMileage = questions.find(q => q.name === QuestionEnum.vehicleEstimatedMileage) as QuestionAnswer<number>
    const businessMileage = questions.find(q => q.name === QuestionEnum.vehicleBusinessMileage) as QuestionAnswer<number>
    const vehicleOvernightParkLocation = questions.find(q => q.name === QuestionEnum.vehicleOvernightParkLocation) as QuestionAnswer<string>
    const vehicleOwner = questions.find(q => q.name === QuestionEnum.vehicleOwner) as QuestionAnswer<string>
    const vehicleKeeper = questions.find(q => q.name === QuestionEnum.vehicleKeeper) as QuestionAnswer<string>
    const commercialVehicleBodyType = questions.find(q => q.name === QuestionEnum.commercialVehicleBodyType) as QuestionAnswer<string>
    const vehicleFuelType = questions.find(q => q.name === QuestionEnum.fuelType) as QuestionAnswer<string>
    const vehicleTransmissionType = questions.find(q => q.name === QuestionEnum.vehicleTransmission) as QuestionAnswer<string>
    const trackerType = questions.find(q => q.name === QuestionEnum.trackerType) as QuestionAnswer<string>
    const trackerModel = questions.find(q => q.name === QuestionEnum.trackerModel) as QuestionAnswer<string>
    const alarmType = questions.find(q => q.name === QuestionEnum.modernCarAlarmType) as QuestionAnswer<string>
    const alarmModel = questions.find(q => q.name === QuestionEnum.alarmModel) as QuestionAnswer<string>
    const alarmFittedBy = questions.find(q => q.name === QuestionEnum.alarmFittedBy) as QuestionAnswer<string>

    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const drivingLicence = questions.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const referralSource = questions.find(q => q.name === QuestionEnum.referralSource) as QuestionAnswer<number>
    const noClaimsProtection = questions.find(q => q.name === QuestionEnum.noClaimsProtection) as QuestionAnswer<string>
    const voluntaryExcess = questions.find(q => q.name === QuestionEnum.voluntaryExcess) as QuestionAnswer<string>
    const residentSince = questions.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<Date>
    const residentSinceBirth = questions.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as QuestionAnswer<string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as QuestionAnswer<string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as QuestionAnswer<string>
    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
    const homeOwner = questions.find(q => q.name === QuestionEnum.homeOwner) as QuestionAnswer<string>
    const numberOfOtherVehiclesInHousehold = questions.find(q => q.name === QuestionEnum.numberOfOtherVehiclesAvailable) as QuestionAnswer<string>
    const driveOtherVehicles = questions.find(q => q.name === QuestionEnum.doYouDriveAnyVehicleBesidesThisOne) as QuestionAnswer<string>
    const otherVehicles = questions.find(q => q.name === QuestionEnum.otherVehiclesYouDrive) as QuestionAnswer<string>

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
      || employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
      if (occupation) {
        occupation.value = (title.value === DLDCommonValuesEnum.Title.Mr || title.value === DLDCommonValuesEnum.Title.DoctorMale)
          ? DLDCommonValuesEnum.Occupation.Househusband
          : DLDCommonValuesEnum.Occupation.Housewife
      }
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
    }

    // Addtional drivers
    const additionalDrivers = questions.find(q => q.name === QuestionEnum.additionalDriver) as QuestionAnswer<number>
    const adDrivers: AdditionalDriver[] = []

    if (additionalDrivers) {
      for (let i = 0; i < additionalDrivers.subPageAnswers.length; i += 1) {
        const subPage = additionalDrivers.subPageAnswers[i]
        const adTitle = subPage.answers.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
        const adFirstName = subPage.answers.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
        const adLastName = subPage.answers.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
        const adDateOfBirth = subPage.answers.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
        const adDrivingLicence = subPage.answers.find(q => q.name === QuestionEnum.drivingLicence) as QuestionAnswer<string>
        const adRelationshipToPolicyholder = subPage.answers.find(q => q.name === QuestionEnum.relationshipToPolicyHolder) as QuestionAnswer<string>
        const adEmploymentStatus = subPage.answers.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
        const adOccupation = subPage.answers.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
        const adBusinessCategory = subPage.answers.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
        const adPrn = subPage.answers.find(q => q.name === QuestionEnum.PRN) as QuestionAnswer<number>
        const adresidentSince = subPage.answers.find(q => q.name === QuestionEnum.residentSince) as QuestionAnswer<Date>
        const adresidentSinceBirth = subPage.answers.find(q => q.name === QuestionEnum.residentSinceBirth) as QuestionAnswer<string>
        const adMaritalStatus = subPage.answers.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
        const adDriveOtherVehicles = subPage.answers.find(q => q.name === QuestionEnum.doYouDriveAnyVehicleBesidesThisOne) as QuestionAnswer<string>
        const adOtherVehicles = subPage.answers.find(q => q.name === QuestionEnum.otherVehiclesYouDrive) as QuestionAnswer<string>

        if (adEmploymentStatus.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.Retired
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
        }
        else if (adEmploymentStatus.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.Unemployed
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
        }
        else if (adEmploymentStatus.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
          || adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
          adOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
        }
        else if (adEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
          adOccupation.value = (adTitle.value === DLDCommonValuesEnum.Title.Mr || adTitle.value === DLDCommonValuesEnum.Title.DoctorMale)
            ? DLDCommonValuesEnum.Occupation.Househusband
            : DLDCommonValuesEnum.Occupation.Housewife
          adBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
        }

        adDrivers.push({
          title: adTitle.value!,
          firstName: adFirstName.value!,
          lastName: adLastName.value!,
          dateOfBirth: adDateOfBirth.value!,
          drivingLicence: adDrivingLicence.value!,
          licenceType: DLDCommonValuesEnum.CarLicenceType.FullUK,
          relationshiptoPolicyholder: adRelationshipToPolicyholder.value!,
          employmentStatus: adEmploymentStatus.value!,
          occupation: adOccupation.value!,
          businessCategory: adBusinessCategory.value!,
          residentSince: adresidentSinceBirth?.value === DLDCommonValuesEnum.Boolean.Yes ? adDateOfBirth.value! : adresidentSince ? adresidentSince.value : null,
          classOfUse: null,
          maritalStatus: adMaritalStatus?.value,
          hasMedicalConditions: null,
          dvlaReportableMedicalConditions: null,
          claims: [],
          prn: adPrn?.value ?? null,
          farePayingPassengers: null,
          licenceDate: null,
          otherLicensingAuthorities: '',
          mainLicensingAuthority: '',
          rideSharingApp: null,
          hasHadPolicyDeclaredVoid: null,
          secondaryOccupation: null,
          taxiBadgeHeldDate: null,
          otherVehiclesOwned: null,
          hasConvictions: null,
          liveInVehicleFullTime: null,
          hadInsuranceRefused: null,
          drivingFrequency: null,
          hasCriminalConvictions: null,
          hasSecondaryEmployment: null,
          hasHadPreviousPolicyCancelled: null,
          secondaryEmploymentStatus: null,
          secondaryBusinessCategory: null,
          hasClaims: null,
          restrictedLicense: null,
          voidReason: null,
          residentSinceBirth: adresidentSinceBirth?.value === DLDCommonValuesEnum.Boolean.Yes,
          convictions: [],
          medicalConditions: [],
          hasCCJs: null,
          psvLicenceDate: null,
          psvLicenceNumber: null,
          operatingInLondonArea: null,
          restrictedLicenseType: null,
          otherVehiclesYouDrive: adDriveOtherVehicles?.value === DLDCommonValuesEnum.Boolean.Yes ? adOtherVehicles.value : null,
          useOfAnotherCar: null,
          pSVOLicenceNumber: null,
          pSVONamedProposer: null,
          hasPSVOLicence: null,
          pSVONamedProposerReason: null,
          noPSVOLicenseReason: null,
          address: null,
        })
      }
    }

    // Cover details
    const classOfUse = questions.find(q => q.name === QuestionEnum.classOfUse || q.name === QuestionEnum.commercialVehicleClassOfUse) as QuestionAnswer<string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>

    const quoteRequest = new Type()

    if (!(quoteRequest instanceof CarQuoteRequest || quoteRequest instanceof CarMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Yoga.`)

    quoteRequest.car = new Car()
    quoteRequest.car.abiCode = vehicleLookup.value!.abiCode
    quoteRequest.car.registration = vehicleLookup.value!.registration
    quoteRequest.car.value = vehicleValue.value!
    quoteRequest.car.registeredKeeper = vehicleKeeper.value!
    quoteRequest.car.registeredOwner = vehicleOwner.value!
    quoteRequest.car.overnightParkLocation = vehicleOvernightParkLocation.value!
    quoteRequest.car.purchaseDate = vehiclePurchaseDate.value!
    quoteRequest.car.yearOfManufacture = vehicleLookup.value!.yearOfManufacture
    quoteRequest.car.keptAtHomeAddress = true
    quoteRequest.car.make = vehicleLookup.value!.make
    quoteRequest.car.model = vehicleLookup.value!.model
    quoteRequest.car.purchaseDate = vehiclePurchaseDate.value!
    quoteRequest.car.bodyType = commercialVehicleBodyType?.value || vehicleLookup.value!.bodyType || null
    quoteRequest.car.transmission = vehicleTransmissionType?.value || vehicleLookup.value?.transmissionType || null
    quoteRequest.car.cubicCapacity = vehicleLookup.value!.engineSize
    quoteRequest.car.fuelType = vehicleFuelType?.value || vehicleLookup.value?.fuelType || null
    quoteRequest.car.trackerType = trackerType?.value
    quoteRequest.car.trackerModel = !trackerType || trackerType?.value === 'CarTrackerType.None' ? null : trackerModel.value
    quoteRequest.car.alarmType = alarmType?.value
    quoteRequest.car.alarmModel = !alarmType || alarmType?.value === 'ModernCarAlarmType.None' ? null : alarmModel.value
    quoteRequest.car.alarmFittedBy = !alarmType || alarmType?.value === 'ModernCarAlarmType.None' ? null : alarmFittedBy.value

    quoteRequest.mileage = vehicleEstimatedMileage.value!
    quoteRequest.businessMileage = businessMileage?.value ?? 0

    quoteRequest.proposer = new CarProposer()
    quoteRequest.proposer.title = title.value!
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.drivingLicence = drivingLicence!.value!
    quoteRequest.proposer.licenceType = DLDCommonValuesEnum.CarLicenceType.FullUK
    quoteRequest.proposer.employmentStatus = employmentStatus.value!
    quoteRequest.proposer.occupation = occupation.value!
    quoteRequest.proposer.businessCategory = businessCategory.value!
    quoteRequest.proposer.address = address.value!
    quoteRequest.proposer.prn = null
    quoteRequest.proposer.hasSecondaryEmployment = hasSecondaryEmployment?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.secondaryOccupation = quoteRequest.proposer.hasSecondaryEmployment ? secondaryOccupation.value : null
    quoteRequest.proposer.secondaryBusinessCategory = quoteRequest.proposer.hasSecondaryEmployment ? secondaryBusinessCategory.value : null
    quoteRequest.proposer.maritalStatus = maritalStatus?.value
    quoteRequest.proposer.homeOwner = homeOwner?.value === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.proposer.numberOfOtherVehiclesAvailable = numberOfOtherVehiclesInHousehold?.value
    quoteRequest.proposer.otherVehiclesYouDrive = driveOtherVehicles?.value === DLDCommonValuesEnum.Boolean.Yes ? otherVehicles.value : null

    if (residentSinceBirth)
      quoteRequest.proposer.residentSince = residentSinceBirth.value === DLDCommonValuesEnum.Boolean.Yes ? dateOfBirth.value! : residentSince.value

    quoteRequest.address = address.value!
    quoteRequest.additionalDrivers = adDrivers
    quoteRequest.classOfUse = classOfUse.value!
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.voluntaryExcess = voluntaryExcess && voluntaryExcess.value ? voluntaryExcess.value : DLDCommonValuesEnum.VolXs.VolXs250
    quoteRequest.coverType = DLDCommonValuesEnum.CoverType.Comprehensive
    quoteRequest.businessSourceId = referralSource?.value
    quoteRequest.noClaimsProtection = noClaimsProtection && noClaimsProtection.value!

    return quoteRequest
  }

  getPropertyQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new() => T): T {
    // Personal details
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const sameAsHomeAddress = questions.find(q => q.name === QuestionEnum.sameAsHomeAddress) as QuestionAnswer<string>
    const propserAddress = questions.find(q => q.name === QuestionEnum.proposerAddress) as QuestionAnswer<Address>
    const maritalStatus = questions.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
    const employmentStatus = questions.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
    const occupation = questions.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
    const businessCategory = questions.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
    const studentType = questions.find(q => q.name === QuestionEnum.studentType) as QuestionAnswer<string>
    const hasSecondaryEmployment = questions.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as QuestionAnswer<string>
    const secondaryOccupation = questions.find(q => q.name === QuestionEnum.secondaryOccupation) as QuestionAnswer<string>
    const secondaryBusinessCategory = questions.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as QuestionAnswer<string>
    const contactPhoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const hasBeenBankrupt = questions.find(q => q.name === QuestionEnum.everDeclaredBankrupt) as QuestionAnswer<string>
    const hadSpecialConditions = questions.find(q => q.name === QuestionEnum.hadSpecialConditions) as QuestionAnswer<string>
    const insuranceDeclined = questions.find(q => q.name === QuestionEnum.insuranceDeclined) as QuestionAnswer<string>
    const hasCriminalConvictions = questions.find(q => q.name === QuestionEnum.hasCriminalConvictions) as QuestionAnswer<string>
    const hasClaims = questions.find(q => q.name === QuestionEnum.hasClaims) as QuestionAnswer<string>
    const yearsWithNoClaimsBuilding = questions.find(q => q.name === QuestionEnum.yearsWithNoClaimsBuilding) as QuestionAnswer<string>
    const yearsWithNoClaimsContents = questions.find(q => q.name === QuestionEnum.yearsWithNoClaimsContents) as QuestionAnswer<string>
    const buildingsVoluntaryExcess = questions.find(q => q.name === QuestionEnum.buildingsVoluntaryExcess) as QuestionAnswer<string>
    const contentsVoluntaryExcess = questions.find(q => q.name === QuestionEnum.contentsVoluntaryExcess) as QuestionAnswer<string>
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.coverEffectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>

    // Property Details
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const homeOwnedOrRented = questions.find(q => q.name === QuestionEnum.homeOwnedOrRented) as QuestionAnswer<string>
    const buildingsContentsOrBoth = questions.find(q => q.name === QuestionEnum.buildingsContentsOrBoth) as QuestionAnswer<string>
    const propertyType = questions.find(q => q.name === QuestionEnum.propertyType) as QuestionAnswer<string>
    const homeType = questions.find(q => q.name === QuestionEnum.homeType) as QuestionAnswer<string>
    const bedroomCount = questions.find(q => q.name === QuestionEnum.bedroomCount) as QuestionAnswer<string>
    const bathroomCount = questions.find(q => q.name === QuestionEnum.bathroomCount) as QuestionAnswer<string>
    const livingRoomCount = questions.find(q => q.name === QuestionEnum.livingRoomCount) as QuestionAnswer<string>
    const otherRoomCount = questions.find(q => q.name === QuestionEnum.otherRoomCount) as QuestionAnswer<string>
    const approximateBuildYear = questions.find(q => q.name === QuestionEnum.approximateBuildYear) as QuestionAnswer<number>
    const roofMaterial = questions.find(q => q.name === QuestionEnum.roofMaterial) as QuestionAnswer<string>
    const roofFlatness = questions.find(q => q.name === QuestionEnum.roofFlatness) as QuestionAnswer<string>
    const externalWallMaterial = questions.find(q => q.name === QuestionEnum.externalWallMaterial) as QuestionAnswer<string>
    const anyHistoryOfSubsidence = questions.find(q => q.name === QuestionEnum.anyHistoryOfSubsidence) as QuestionAnswer<string>
    const anyHistoryOfUnderpinning = questions.find(q => q.name === QuestionEnum.anyHistoryOfUnderpinning) as QuestionAnswer<string>
    const anyHistoryOfFlooding = questions.find(q => q.name === QuestionEnum.anyHistoryOfFlooding) as QuestionAnswer<string>
    const anyBuildingWorkInProgress = questions.find(q => q.name === QuestionEnum.anyBuildingWorkInProgress) as QuestionAnswer<string>
    const propertyDistanceToWatercourse = questions.find(q => q.name === QuestionEnum.propertyDistanceToWatercourse) as QuestionAnswer<string>
    const propertyIsListedBuilding = questions.find(q => q.name === QuestionEnum.propertyIsListedBuilding) as QuestionAnswer<string>
    const mainHome = questions.find(q => q.name === QuestionEnum.mainHome) as QuestionAnswer<string>
    const homeIsInGoodStateOfRepair = questions.find(q => q.name === QuestionEnum.homeIsInGoodStateOfRepair) as QuestionAnswer<string>
    const largeTreesNearby = questions.find(q => q.name === QuestionEnum.largeTreesNearby) as QuestionAnswer<string>
    const costToRebuildProperty = questions.find(q => q.name === QuestionEnum.costToRebuildProperty) as QuestionAnswer<number>
    const includeAccidentalDamageForBuildings = questions.find(q => q.name === QuestionEnum.includeAccidentalDamageForBuildings) as QuestionAnswer<string>
    const includeAccidentalDamageForContents = questions.find(q => q.name === QuestionEnum.includeAccidentalDamageForContents) as QuestionAnswer<string>

    // Home Contents Occupancy and Security
    const hasHighValueItems = questions.find(q => q.name === QuestionEnum.hasHighValueItems) as QuestionAnswer<string>
    const unspecifiedItemValue = questions.find(q => q.name === QuestionEnum.unspecifiedItemValue) as QuestionAnswer<string>
    const costToReplaceContents = questions.find(q => q.name === QuestionEnum.costToReplaceContents) as QuestionAnswer<number>
    const hasPurchasedProperty = questions.find(q => q.name === QuestionEnum.hasPurchasedProperty) as QuestionAnswer<string>
    const dateOfPurchase = questions.find(q => q.name === QuestionEnum.dateOfPurchase) as QuestionAnswer<Date>
    const howLongAtProperty = questions.find(q => q.name === QuestionEnum.howLongHaveYouLivedAtTheProperty) as QuestionAnswer<string>
    const whoLivesAtProperty = questions.find(q => q.name === QuestionEnum.whoLivesAtProperty) as QuestionAnswer<string>
    const howManyAdults = questions.find(q => q.name === QuestionEnum.howManyAdults) as QuestionAnswer<string>
    const howManyChildren = questions.find(q => q.name === QuestionEnum.howManyChildren) as QuestionAnswer<string>
    const anyAnimals = questions.find(q => q.name === QuestionEnum.anyAnimals) as QuestionAnswer<string>
    const anySmokers = questions.find(q => q.name === QuestionEnum.anySmokers) as QuestionAnswer<string>
    const whenAtProperty = questions.find(q => q.name === QuestionEnum.whenAtProperty) as QuestionAnswer<string>
    const howLongPropertyUnoccupied = questions.find(q => q.name === QuestionEnum.howLongPropertyUnoccupied) as QuestionAnswer<string>
    const anyBusinessUse = questions.find(q => q.name === QuestionEnum.anyBusinessUse) as QuestionAnswer<string>
    const typeOfAlarm = questions.find(q => q.name === QuestionEnum.typeOfAlarm) as QuestionAnswer<string>
    const alarmProfessionallyInstalledMaintained = questions.find(q => q.name === QuestionEnum.alarmProfessionallyInstalledMaintained) as QuestionAnswer<string>
    const typeOfLocks = questions.find(q => q.name === QuestionEnum.typeOfLocks) as QuestionAnswer<string>
    const lessSecureDoors = questions.find(q => q.name === QuestionEnum.lessSecureDoors) as QuestionAnswer<string>
    const otherTypeOfLocks = questions.find(q => q.name === QuestionEnum.otherTypeOfLocks) as QuestionAnswer<string>
    const hasKeyOperatedLocksOnAllWindows = questions.find(q => q.name === QuestionEnum.hasKeyOperatedLocksOnAllWindows) as QuestionAnswer<string>
    const workingSmokeDetectors = questions.find(q => q.name === QuestionEnum.workingSmokeDetectors) as QuestionAnswer<string>
    const inVerifiedNeighbourhoodWatchArea = questions.find(q => q.name === QuestionEnum.inVerifiedNeighbourhoodWatchArea) as QuestionAnswer<string>
    const anyUnspecifiedItemsQuestion = questions.find(q => q.name === QuestionEnum.anyUnspecifiedItems) as QuestionAnswer<string>

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Retired
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
      || employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
      if (occupation)
        occupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
    }
    else if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
      if (occupation) {
        occupation.value = (title.value === DLDCommonValuesEnum.Title.Mr || title.value === DLDCommonValuesEnum.Title.DoctorMale)
          ? DLDCommonValuesEnum.Occupation.Househusband
          : DLDCommonValuesEnum.Occupation.Housewife
      }
      if (businessCategory)
        businessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
    }

    if (employmentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
      if (occupation)
        occupation.value = null
      if (businessCategory)
        businessCategory.value = null
    }
    else if (occupation?.value?.toLowerCase().includes('student')) {
      if (businessCategory)
        businessCategory.value = null
    }
    else {
      if (studentType)
        studentType.value = null
    }

    const quoteRequest = new Type()

    if (!(quoteRequest instanceof PropertyQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Yoga.`)

    quoteRequest.riskProposer = new RiskProposer()
    quoteRequest.riskProposer.title = title.value!
    quoteRequest.riskProposer.firstName = firstName.value!
    quoteRequest.riskProposer.lastName = lastName.value!
    quoteRequest.riskProposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.riskProposer.maritalStatus = maritalStatus.value!
    quoteRequest.riskProposer.employmentStatus = employmentStatus.value!
    quoteRequest.riskProposer.occupation = occupation.value!
    quoteRequest.riskProposer.businessType = businessCategory.value!
    quoteRequest.riskProposer.studentType = studentType.value!
    quoteRequest.riskProposer.hasSecondaryEmployment = hasSecondaryEmployment.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.riskProposer.secondaryOccupation = hasSecondaryEmployment.value! === DLDCommonValuesEnum.Boolean.Yes ? secondaryOccupation.value! : null
    quoteRequest.riskProposer.secondaryBusinessType = hasSecondaryEmployment.value! === DLDCommonValuesEnum.Boolean.Yes ? secondaryBusinessCategory.value! : null
    quoteRequest.riskProposer.telephoneNumber = contactPhoneNumber.value!
    quoteRequest.riskProposer.emailAddress = email.value!
    quoteRequest.riskProposer.hasDeclaredBankruptcy = hasBeenBankrupt.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.riskProposer.hadSpecialTermsImposed = hadSpecialConditions.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.riskProposer.hadInsuranceRefusedCancelledOrDeclined = insuranceDeclined.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.riskProposer.hasUnspentNonMotoringConvictions = hasCriminalConvictions.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.riskProposer.hasClaims = hasClaims.value! === DLDCommonValuesEnum.Boolean.Yes
    quoteRequest.riskProposer.voluntaryExcess = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Buildings || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? buildingsVoluntaryExcess.value! : null
    quoteRequest.riskProposer.voluntaryExcess2 = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? contentsVoluntaryExcess.value! : null

    if (sameAsHomeAddress.value! === DLDCommonValuesEnum.Boolean.No)
      quoteRequest.riskProposer.address = propserAddress.value!
    else
      quoteRequest.riskProposer.address = address.value!

    quoteRequest.yearsWithNoClaimsBuilding = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Buildings || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? yearsWithNoClaimsBuilding.value! : null
    quoteRequest.yearsWithNoClaimsContents = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? yearsWithNoClaimsContents.value! : null
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.address = address.value!

    const property = new Property()
    property.address = address.value!
    property.own = homeOwnedOrRented.value!
    property.typeOfPolicy = buildingsContentsOrBoth.value!
    property.propertyType = propertyType.value!
    property.subType = homeType.value!
    property.numberOfBedrooms = bedroomCount.value!
    property.numberOfBathrooms = bathroomCount.value!
    property.numberOfOtherRooms = otherRoomCount.value!
    property.numberOfReceptionRooms = livingRoomCount.value!
    property.yearOfBuild = approximateBuildYear.value!
    property.roofConstruction = roofMaterial.value!
    property.flatRoofPercentage = roofFlatness.value!
    property.externalWallConstruction = externalWallMaterial.value!
    property.hasSubsidence = anyHistoryOfSubsidence.value! === DLDCommonValuesEnum.Boolean.Yes
    property.hasBeenUnderpinned = anyHistoryOfUnderpinning.value! === DLDCommonValuesEnum.Boolean.Yes
    property.hasBeenFlooded = anyHistoryOfFlooding.value! === DLDCommonValuesEnum.Boolean.Yes
    property.anyCurrentBuildingWork = anyBuildingWorkInProgress.value! === DLDCommonValuesEnum.Boolean.Yes
    property.distanceFromWater = propertyDistanceToWatercourse.value!
    property.listedBuilding = propertyIsListedBuilding.value!
    property.isMainHome = mainHome.value!
    property.inGoodStateOfRepair = homeIsInGoodStateOfRepair.value! === DLDCommonValuesEnum.Boolean.Yes
    property.nearTrees = largeTreesNearby.value! === DLDCommonValuesEnum.Boolean.Yes
    property.costToRebuild = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Buildings || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? costToRebuildProperty.value! : null
    property.hasHighValueItems = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? hasHighValueItems.value! === DLDCommonValuesEnum.Boolean.Yes : null
    property.hasUnspecifiedItems = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? anyUnspecifiedItemsQuestion.value! === DLDCommonValuesEnum.Boolean.Yes : null
    property.unspecifiedItemsValue = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents)
    && anyUnspecifiedItemsQuestion.value! === DLDCommonValuesEnum.Boolean.Yes
      ? unspecifiedItemValue.value!
      : null!
    property.costToReplaceContents = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? costToReplaceContents.value! : null!
    property.notYetPurchased = hasPurchasedProperty.value! === DLDCommonValuesEnum.Boolean.No
    property.purchasedDate = hasPurchasedProperty.value! === DLDCommonValuesEnum.Boolean.Yes ? dateOfPurchase.value! : null
    property.howLongAtProperty = hasPurchasedProperty.value! === DLDCommonValuesEnum.Boolean.No ? howLongAtProperty.value! : null
    property.whoLivesAtProperty = whoLivesAtProperty.value!
    property.howManyAdults = whoLivesAtProperty.value! === DLDCommonValuesEnum.PropertyInsuranceWhoLivesAtProperty.PolicyholderOnly ? null : howManyAdults.value!
    property.howManyChildren = whoLivesAtProperty.value! === DLDCommonValuesEnum.PropertyInsuranceWhoLivesAtProperty.PolicyholderOnly ? null : howManyChildren.value!
    property.animalsInProperty = anyAnimals.value!
    property.anySmokers = anySmokers.value! === DLDCommonValuesEnum.Boolean.Yes
    property.whenAreYouAtProperty = whenAtProperty.value!
    property.howLongIsPropertyUnoccupied = howLongPropertyUnoccupied.value!
    property.anyBusinessUse = anyBusinessUse.value!
    property.typeOfAlarm = typeOfAlarm.value!
    property.alarmProfessionallyInstalledMaintained = typeOfAlarm.value === null || typeOfAlarm.value! === DLDCommonValuesEnum.PropertyInsuranceTypeOfAlarm.NoAlarm ? null : alarmProfessionallyInstalledMaintained.value! === DLDCommonValuesEnum.Boolean.Yes
    property.typeOfLocks = typeOfLocks.value!
    property.lessSecureDoorType = lessSecureDoors.value!
    property.hasKeyOperatedLocksOnAllWindows = hasKeyOperatedLocksOnAllWindows.value! === DLDCommonValuesEnum.Boolean.Yes
    property.includeAccidentalDamageForBuildings = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Buildings || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? includeAccidentalDamageForBuildings.value! === DLDCommonValuesEnum.Boolean.Yes : null
    property.includeAccidentalDamageForContents = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents) ? includeAccidentalDamageForContents.value! === DLDCommonValuesEnum.Boolean.Yes : null
    property.workingSmokeAlarmType = workingSmokeDetectors.value!
    property.inVerifiedNeighbourhoodWatchArea = inVerifiedNeighbourhoodWatchArea?.value === DLDCommonValuesEnum.Boolean.Yes
    property.additionalExteriorDoorType = otherTypeOfLocks.value!
    property.specifiedItems = (buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.Contents || buildingsContentsOrBoth.value === DLDCommonValuesEnum.PropertyInsuranceTypeOfPolicy.BuildingsAndContents)
    && hasHighValueItems.value! === DLDCommonValuesEnum.Boolean.Yes
      ? this.getSpecifiedItems(questions)
      : []
    property.propertyClaims = hasClaims.value! === DLDCommonValuesEnum.Boolean.Yes ? this.getPropertyClaims(questions) : []

    quoteRequest.properties.push(property)

    // Additional policyholders
    const additionalPolicyholders = questions.find(q => q.name === QuestionEnum.additionalPolicyholders) as QuestionAnswer<string>

    if (additionalPolicyholders?.subPageAnswers?.length > 0) {
      const subPage = additionalPolicyholders.subPageAnswers[0]
      const apTitle = subPage.answers.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
      const apFirstName = subPage.answers.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
      const apLastName = subPage.answers.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
      const apDateOfBirth = subPage.answers.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
      const apRelationshipToPolicyholder = subPage.answers.find(q => q.name === QuestionEnum.relationshipToPolicyHolder) as QuestionAnswer<string>
      const apEmploymentStatus = subPage.answers.find(q => q.name === QuestionEnum.employmentStatus) as QuestionAnswer<string>
      const apOccupation = subPage.answers.find(q => q.name === QuestionEnum.occupation) as QuestionAnswer<string>
      const apBusinessCategory = subPage.answers.find(q => q.name === QuestionEnum.businessCategory) as QuestionAnswer<string>
      const apSecondaryOccupation = subPage.answers.find(q => q.name === QuestionEnum.secondaryOccupation) as QuestionAnswer<string>
      const apSecondaryBusinessCategory = subPage.answers.find(q => q.name === QuestionEnum.secondaryBusinessCategory) as QuestionAnswer<string>
      const apMaritalStatus = subPage.answers.find(q => q.name === QuestionEnum.maritalStatus) as QuestionAnswer<string>
      const apHasSecondaryEmployment = subPage.answers.find(q => q.name === QuestionEnum.hasSecondaryEmployment) as QuestionAnswer<string>
      const apStudentType = subPage.answers.find(q => q.name === QuestionEnum.studentType) as QuestionAnswer<string>

      if (apEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Retired) {
        if (apOccupation)
          apOccupation.value = DLDCommonValuesEnum.Occupation.Retired
        if (apBusinessCategory)
          apBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonRetired
      }
      else if (apEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.Unemployed) {
        if (apOccupation)
          occupation.value = DLDCommonValuesEnum.Occupation.Unemployed
        if (apBusinessCategory)
          apBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NonUnemployed
      }
      else if (apEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FinanciallyAssisted
        || apEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.IndependentMeans) {
        if (apOccupation)
          apOccupation.value = DLDCommonValuesEnum.Occupation.NotInEmployment
        if (apBusinessCategory)
          apBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NotInEmployment
      }
      else if (apEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.NotEmployedDueToDisability) {
        if (apOccupation)
          apOccupation.value = DLDCommonValuesEnum.Occupation.NotEmployedDueToDisability
        if (apBusinessCategory)
          apBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneNotEmployedduetoDisability
      }
      else if (apEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.HouseholdDuties) {
        if (apOccupation) {
          apOccupation.value = (apTitle.value === DLDCommonValuesEnum.Title.Mr || apTitle.value === DLDCommonValuesEnum.Title.DoctorMale)
            ? DLDCommonValuesEnum.Occupation.Househusband
            : DLDCommonValuesEnum.Occupation.Housewife
        }
        if (apBusinessCategory)
          apBusinessCategory.value = DLDCommonValuesEnum.BusinessCategory.NoneHouseholdDuties
      }

      if (apEmploymentStatus?.value === DLDCommonValuesEnum.EmploymentStatus.FullOrPartTimeEducation) {
        if (apOccupation)
          apOccupation.value = null
        if (apBusinessCategory)
          apBusinessCategory.value = null
      }
      else if (apOccupation?.value?.toLowerCase().includes('student')) {
        if (apBusinessCategory)
          apBusinessCategory.value = null
      }
      else {
        if (apStudentType)
          apStudentType.value = null
      }

      quoteRequest.additionalPolicyholder = new AdditionalPolicyholder()
      quoteRequest.additionalPolicyholder.title = apTitle.value!
      quoteRequest.additionalPolicyholder.firstName = apFirstName.value!
      quoteRequest.additionalPolicyholder.lastName = apLastName.value!
      quoteRequest.additionalPolicyholder.dateOfBirth = apDateOfBirth.value!
      quoteRequest.additionalPolicyholder.relationshiptoPolicyholder = apRelationshipToPolicyholder.value!
      quoteRequest.additionalPolicyholder.employmentStatus = apEmploymentStatus.value!
      quoteRequest.additionalPolicyholder.occupation = apOccupation.value!
      quoteRequest.additionalPolicyholder.businessType = apBusinessCategory.value!
      quoteRequest.additionalPolicyholder.maritalStatus = apMaritalStatus?.value
      quoteRequest.additionalPolicyholder.hasSecondaryEmployment = apHasSecondaryEmployment.value! === DLDCommonValuesEnum.Boolean.Yes
      quoteRequest.additionalPolicyholder.secondaryOccupation = apHasSecondaryEmployment.value! === DLDCommonValuesEnum.Boolean.Yes ? apSecondaryOccupation.value! : null
      quoteRequest.additionalPolicyholder.secondaryBusinessType = apHasSecondaryEmployment.value! === DLDCommonValuesEnum.Boolean.Yes ? apSecondaryBusinessCategory.value! : null
      quoteRequest.additionalPolicyholder.studentType = apStudentType.value!
    }

    return quoteRequest
  }

  private getPropertyClaims(questions: IQuestionAnswer[]): PropertyClaim[] {
    const claimsQuestion = questions.find(q => q.name === QuestionEnum.claims) as QuestionAnswer<ClaimsQuestion>
    const claims: PropertyClaim[] = []

    if (claimsQuestion) {
      for (let i = 0; i < claimsQuestion.subPageAnswers.length; i += 1) {
        const claimDate = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimDate) as QuestionAnswer<Date>
        const claimType = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimType) as QuestionAnswer<string>
        const claimStatus = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimStatus) as QuestionAnswer<string>
        const claimCause = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.homeClaimCause) as QuestionAnswer<string>
        const claimAmount = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.claimAmount) as QuestionAnswer<number>
        const claimHappenedAtProperty = claimsQuestion.subPageAnswers[i].answers.find(q => q.name === QuestionEnum.homeClaimAtCurrentProperty) as QuestionAnswer<string>

        claims.push({
          claimDate: claimDate.value!,
          claimType: claimType.value!,
          claimStatus: claimStatus.value!,
          value: claimAmount.value!,
          happenedAtProperty: claimHappenedAtProperty.value! === DLDCommonValuesEnum.Boolean.Yes,
          claimCause: claimCause.value!,
        })
      }
    }
    return claims
  }

  private getSpecifiedItems(questions: IQuestionAnswer[]): SpecifiedItem[] {
    const specifiedItems = questions.find(q => q.name === QuestionEnum.specifiedItemsSubpage) as QuestionAnswer<boolean>
    const specItems: SpecifiedItem[] = []

    if (specifiedItems) {
      for (let i = 0; i < specifiedItems.subPageAnswers.length; i += 1) {
        const subPage = specifiedItems.subPageAnswers[i]
        const typeOfItem = subPage.answers.find(q => q.name === QuestionEnum.typeOfItem) as QuestionAnswer<string>
        const insuredItemsCarriedOutsideProperty = subPage.answers.find(q => q.name === QuestionEnum.insuredItemsCarriedOutsideProperty) as QuestionAnswer<string>
        const replacementCost = subPage.answers.find(q => q.name === QuestionEnum.replacementCost) as QuestionAnswer<number>
        const specifiedItemDescription = subPage.answers.find(q => q.name === QuestionEnum.specifiedItemDescription) as QuestionAnswer<string>

        specItems.push({
          typeOfItem: typeOfItem.value!,
          insuredItemsCarriedOutsideProperty: insuredItemsCarriedOutsideProperty.value!,
          replacementCost: replacementCost.value!,
          specifiedItemDescription: specifiedItemDescription.value!,
        })
      }
    }

    return specItems
  }

  getMTARequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new() => T): T {
    const mtaRequest = this.getQuoteRequest(questions, Type)

    if (!(mtaRequest instanceof CarMTAQuoteRequest) && !(mtaRequest instanceof PropertyMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for Yoga.`)

    const reasonForChange = questions.find(q => q.name === QuestionEnum.reasonForChange) as QuestionAnswer<string>
    mtaRequest.reasonForChange = reasonForChange.value!
    return mtaRequest
  }

  mapRiskToAnswers(risk: Risk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    if (risk instanceof PropertyRisk) {
      const propertyRisk = risk as PropertyRisk
      return this.mapPropertyRiskToAnswers(propertyRisk, account, pages)
    }

    if (risk instanceof CarRisk) {
      const carRisk = risk as CarRisk
      return this.mapCarRiskToAnswers(carRisk, account, pages)
    }

    throw new Error('Unsupported risk type for Yoga.')
  }

  mapCarRiskToAnswers(risk: CarRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}
    this.mapAccountToAnswers(pageAnswers, account, pages)

    const vehicle = new Vehicle()
    vehicle.registration = risk.vehicle.registrationNumber
    vehicle.abiCode = risk.vehicle.car.abiCode ?? risk.vehicle.unknownABICode
    vehicle.make = risk.vehicle.car.makeDescription
    vehicle.model = risk.vehicle.car.modelDescription
    vehicle.bodyType = risk.vehicle.bodyType
    vehicle.transmissionType = risk.vehicle.vehicleTransmission?.uniqueId
    vehicle.fuelType = risk.vehicle.fuelType?.uniqueId
    vehicle.value = risk.vehicle.value
    vehicle.purchaseDate = risk.vehicle.purchaseDate
    vehicle.yearOfManufacture = risk.vehicle.yearOfManufacture

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleLookup, vehicle, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.customerVehicleValue, risk.vehicle.value, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfPurchase, risk.vehicle.purchaseDate, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.proposer.title.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.drivingLicence, risk.proposer.drivingLicence, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleEstimatedMileage, risk.vehicle.annualMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleBusinessMileage, risk.vehicle.businessMileage === 0 ? null : risk.vehicle.businessMileage, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleKeeper, risk.vehicle.registeredKeeper.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOwner, risk.vehicle.registeredOwner.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleOvernightParkLocation, risk.vehicle.overnightParkLocation.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.proposer.employmentStatus.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.proposer.occupation.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.proposer.businessCategory.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.classOfUse, risk.classOfUse.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.commercialVehicleClassOfUse, risk.classOfUse.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.referralSource, risk.businessSource?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.effectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.voluntaryExcess, risk.voluntaryExcess.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.noClaimsProtection, risk.noClaimsProtection ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.commercialVehicleBodyType, risk.vehicle.commercialVehicleBodyType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSinceBirth, risk.proposer.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.residentSince, risk.proposer.residentSince, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.fuelType, risk.vehicle.fuelType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.vehicleTransmission, risk.vehicle.vehicleTransmission?.uniqueId, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trackerType, risk.vehicle.trackerType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.trackerModel, risk.vehicle.trackerModel?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.modernCarAlarmType, risk.vehicle.alarmType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.alarmModel, risk.vehicle.alarmModel?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.alarmFittedBy, risk.vehicle.alarmFittedBy?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasSecondaryEmployment, risk.proposer.hasSecondaryEmployment ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryOccupation, risk.proposer.secondaryOccupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryBusinessCategory, risk.proposer.secondaryBusinessCategory?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.maritalStatus, risk.proposer.maritalStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.homeOwner, risk.proposer.homeOwner ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.numberOfOtherVehiclesAvailable, risk.proposer.numberOfOtherVehiclesAvailable?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.doYouDriveAnyVehicleBesidesThisOne, risk.proposer.otherVehiclesYouDrive != null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.otherVehiclesYouDrive, risk.proposer.otherVehiclesYouDrive?.uniqueId, pages)

    const additionalDrivers = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalDriver, risk.additionalDrivers.length, pages)!
    additionalDrivers.forEach((ada) => {
      for (let i = 0; i < risk.additionalDrivers.length; i += 1) {
        const ad = risk.additionalDrivers[i]
        const adTitle = new QuestionAnswer<string>(0, QuestionEnum.title, '', ad.title.uniqueId)
        const adFirstName = new QuestionAnswer<string>(0, QuestionEnum.firstname, '', ad.firstName)
        const adLastName = new QuestionAnswer<string>(0, QuestionEnum.lastName, '', ad.lastName)
        const adDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', ad.dateOfBirth)
        const adResidentSince = new QuestionAnswer<Date>(0, QuestionEnum.residentSince, '', ad.residentSince)
        const adResidentSinceBirth = new QuestionAnswer<string>(0, QuestionEnum.residentSinceBirth, '', ad.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adDrivingLicence = new QuestionAnswer<string>(0, QuestionEnum.drivingLicence, '', ad.drivingLicence)
        const adRelationshipToPolicyholder = new QuestionAnswer<string>(0, QuestionEnum.relationshipToPolicyHolder, '', ad.relationshipToPolicyholder.uniqueId)
        const adEmploymentStatus = new QuestionAnswer<string>(0, QuestionEnum.employmentStatus, '', ad.employmentStatus.uniqueId)
        const adOccupation = new QuestionAnswer<string>(0, QuestionEnum.occupation, '', ad.occupation.uniqueId)
        const adBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.businessCategory, '', ad.businessCategory.uniqueId)
        const adPrn = new QuestionAnswer<number>(0, QuestionEnum.PRN, '', ad.prn, false)

        const adresidentSince = new QuestionAnswer<Date>(0, QuestionEnum.residentSince, '', ad.residentSince)
        const adresidentSinceBirth = new QuestionAnswer<string>(0, QuestionEnum.residentSinceBirth, '', ad.residentSinceBirth ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adMaritalStatus = new QuestionAnswer<string>(0, QuestionEnum.maritalStatus, '', ad.maritalStatus?.uniqueId)
        const adDriveOtherVehicles = new QuestionAnswer<string>(0, QuestionEnum.doYouDriveAnyVehicleBesidesThisOne, '', ad.otherVehiclesYouDrive != null ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const adOtherVehicles = new QuestionAnswer<string>(0, QuestionEnum.otherVehiclesYouDrive, '', ad.otherVehiclesYouDrive?.uniqueId)

        const adPage = new PageAnswers(`Additional Driver ${i + 1}`, [adTitle, adFirstName, adLastName, adDateOfBirth, adDrivingLicence, adRelationshipToPolicyholder, adEmploymentStatus, adOccupation, adBusinessCategory, adPrn, adResidentSince, adResidentSinceBirth, adresidentSince, adresidentSinceBirth, adMaritalStatus, adDriveOtherVehicles, adOtherVehicles])

        ada.subPageAnswers.push(adPage)
      }
    })

    return pageAnswers
  }

  mapPropertyRiskToAnswers(risk: PropertyRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}
    this.mapAccountToAnswers(pageAnswers, account, pages)

    // Proposer Details and Cover
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.title, risk.riskProposer.title?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.riskProposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.riskProposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.riskProposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.proposerAddress, risk.riskProposer.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.sameAsHomeAddress, risk.address.postCode === risk.riskProposer.address.postCode && risk.address.line1 === risk.riskProposer.address.line1 ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.maritalStatus, risk.riskProposer.maritalStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.employmentStatus, risk.riskProposer.employmentStatus?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.occupation, risk.riskProposer.occupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.businessCategory, risk.riskProposer.businessType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.studentType, risk.riskProposer.studentType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasSecondaryEmployment, risk.riskProposer.hasSecondaryEmployment ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryOccupation, risk.riskProposer.secondaryOccupation?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.secondaryBusinessCategory, risk.riskProposer.secondaryBusinessType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mobilePhoneNumber, risk.riskProposer.telephoneNumber, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.customerEmail, risk.riskProposer.emailAddress, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.everDeclaredBankrupt, risk.riskProposer.hasDeclaredBankruptcy ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hadSpecialConditions, risk.riskProposer.hadSpecialTermsImposed ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.insuranceDeclined, risk.riskProposer.hadInsuranceRefusedCancelledOrDeclined ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasCriminalConvictions, risk.riskProposer.hasUnspentNonMotoringConvictions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasClaims, risk.riskProposer.hasClaims ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.yearsWithNoClaimsBuilding, risk.ncdBuildings?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.yearsWithNoClaimsContents, risk.ncdContents?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.buildingsVoluntaryExcess, risk.riskProposer.voluntaryExcess?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.contentsVoluntaryExcess, risk.riskProposer.voluntaryExcess2?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverEffectiveDate, risk.effectiveDate, pages)

    // Property Details
    const property = risk.properties[0]

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, property.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.homeOwnedOrRented, property.own?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.buildingsContentsOrBoth, property.typeOfPolicy?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.propertyType, property.propertyType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.homeType, property.subType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.bedroomCount, property.numberOfBedrooms?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.bathroomCount, property.numberOfBathrooms?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.livingRoomCount, property.numberOfReceptionRooms?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.otherRoomCount, property.numberOfOtherRooms?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.approximateBuildYear, property.yearOfBuild, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.roofMaterial, property.roofConstruction?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.roofFlatness, property.flatRoofPercentage?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.externalWallMaterial, property.externalWallConstruction?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anyHistoryOfSubsidence, property.hasSubsidence ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anyHistoryOfUnderpinning, property.hasBeenUnderpinned ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anyHistoryOfFlooding, property.hasBeenFlooded ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anyBuildingWorkInProgress, property.anyCurrentBuildingWork ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.propertyDistanceToWatercourse, property.distanceFromWater?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.propertyIsListedBuilding, property.listedBuilding?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.mainHome, property.isMainHome?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.homeIsInGoodStateOfRepair, property.inGoodStateOfRepair ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.largeTreesNearby, property.nearTrees ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.costToRebuildProperty, property.costToRebuild, pages)

    if (property.includeAccidentalDamageForBuildings !== null)
      this.createAnswerForQuestion(pageAnswers, QuestionEnum.includeAccidentalDamageForBuildings, property.includeAccidentalDamageForBuildings ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    if (property.includeAccidentalDamageForContents !== null)
      this.createAnswerForQuestion(pageAnswers, QuestionEnum.includeAccidentalDamageForContents, property.includeAccidentalDamageForContents ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    // Home Contents Occupancy and Security
    if (property.hasHighValueItems !== null)
      this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasHighValueItems, property.hasHighValueItems ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    if (property.hasUnspecifiedItems !== null)
      this.createAnswerForQuestion(pageAnswers, QuestionEnum.anyUnspecifiedItems, property.hasUnspecifiedItems ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    if (property.alarmProfessionallyInstalledMaintained !== null)
      this.createAnswerForQuestion(pageAnswers, QuestionEnum.alarmProfessionallyInstalledMaintained, property.alarmProfessionallyInstalledMaintained ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.unspecifiedItemValue, property.unspecifiedItemsValue?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.costToReplaceContents, property.costToReplaceContents, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasPurchasedProperty, property.notYetPurchased ? DLDCommonValuesEnum.Boolean.No : DLDCommonValuesEnum.Boolean.Yes, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfPurchase, property.purchasedDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.howLongHaveYouLivedAtTheProperty, property.howLongAtProperty?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.whoLivesAtProperty, property.whoLivesAtProperty?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.howManyAdults, property.howManyAdults?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.howManyChildren, property.howManyChildren?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anyAnimals, property.animalsInProperty?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anySmokers, property.anySmokers ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.whenAtProperty, property.whenAreYouAtProperty?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.howLongPropertyUnoccupied, property.howLongIsPropertyUnoccupied?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.anyBusinessUse, property.anyBusinessUse?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.typeOfAlarm, property.typeOfAlarm?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.typeOfLocks, property.typeOfLocks?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lessSecureDoors, property.lessSecureDoorType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.otherTypeOfLocks, property.additionalExteriorDoorType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.hasKeyOperatedLocksOnAllWindows, property.hasKeyOperatedLocksOnAllWindows ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.workingSmokeDetectors, property.workingSmokeAlarmType?.uniqueId, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.inVerifiedNeighbourhoodWatchArea, property.inVerifiedNeighbourhoodWatchArea ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)

    const propertyClaims = this.createAnswerForQuestion(pageAnswers, QuestionEnum.claims, property.propertyClaims.length, pages)!
    propertyClaims.forEach((pca) => {
      for (let i = 0; i < property.propertyClaims.length; i += 1) {
        const pc = property.propertyClaims[i]
        const pcClaimDate = new QuestionAnswer<Date>(0, QuestionEnum.claimDate, '', pc.claimDate)
        const pcClaimType = new QuestionAnswer<string>(0, QuestionEnum.claimType, '', pc.claimType?.uniqueId)
        const pcClaimStatus = new QuestionAnswer<string>(0, QuestionEnum.claimStatus, '', pc.claimStatus?.uniqueId)
        const pcClaimCause = new QuestionAnswer<string>(0, QuestionEnum.homeClaimCause, '', pc.claimCause?.uniqueId)
        const pcHappenedAtProperty = new QuestionAnswer<string>(0, QuestionEnum.homeClaimAtCurrentProperty, '', pc.happenedAtProperty ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const pcClaimAmount = new QuestionAnswer<number>(0, QuestionEnum.claimAmount, '', pc.value)

        const adPage = new PageAnswers(`Claim ${i + 1}`, [pcClaimDate, pcClaimType, pcClaimStatus, pcClaimCause, pcHappenedAtProperty, pcClaimAmount])

        pca.subPageAnswers.push(adPage)
      }
    })

    // Additional policy holder
    if (risk.additionalPolicyholders.length > 0) {
      const additionalPolicyHolder = this.createAnswerForQuestion(pageAnswers, QuestionEnum.additionalPolicyholders, risk.additionalPolicyholders.length, pages)!
      const ap = risk.additionalPolicyholders[0]
      const apTitle = new QuestionAnswer<string>(0, QuestionEnum.title, '', ap.title.uniqueId)
      const apFirstName = new QuestionAnswer<string>(0, QuestionEnum.firstname, '', ap.firstName)
      const apLastName = new QuestionAnswer<string>(0, QuestionEnum.lastName, '', ap.lastName)
      const apDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.dateOfBirth, '', ap.dateOfBirth)
      const apMaritalStatus = new QuestionAnswer<string>(0, QuestionEnum.maritalStatus, '', ap.maritalStatus?.uniqueId)
      const apRelationshipToPolicyholder = new QuestionAnswer<string>(0, QuestionEnum.relationshipToPolicyHolder, '', ap.relationshipToPolicyholder.uniqueId)
      const apEmploymentStatus = new QuestionAnswer<string>(0, QuestionEnum.employmentStatus, '', ap.employmentStatus.uniqueId)
      const apOccupation = new QuestionAnswer<string>(0, QuestionEnum.occupation, '', ap.occupation.uniqueId)
      const apBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.businessCategory, '', ap.businessType.uniqueId)
      const apStudentType = new QuestionAnswer<string>(0, QuestionEnum.studentType, '', ap.studentType.uniqueId)
      const apHasSecondaryEmployment = new QuestionAnswer<string>(0, QuestionEnum.hasSecondaryEmployment, '', ap.hasSecondaryEmployment ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
      const apSecondaryBusinessCategory = new QuestionAnswer<string>(0, QuestionEnum.secondaryBusinessCategory, '', ap.secondaryBusinessType.uniqueId)
      const apSecondaryOccupation = new QuestionAnswer<string>(0, QuestionEnum.secondaryOccupation, '', ap.secondaryOccupation.uniqueId)
      const apPage = new PageAnswers(`Additional Proposer`, [apTitle, apFirstName, apLastName, apDateOfBirth, apRelationshipToPolicyholder, apEmploymentStatus, apOccupation, apBusinessCategory, apMaritalStatus, apHasSecondaryEmployment, apSecondaryBusinessCategory, apSecondaryOccupation, apStudentType])
      additionalPolicyHolder[0].subPageAnswers.push(apPage)
    }

    if (property.specifiedItems !== null) {
      const specifiedItems = this.createAnswerForQuestion(pageAnswers, QuestionEnum.specifiedItemsSubpage, property.specifiedItems!.length, pages)!
      specifiedItems.forEach((spi) => {
        for (let i = 0; i < property.specifiedItems.length; i += 1) {
          const specItem = property.specifiedItems![i]
          if (specItem !== undefined && specItem.typeOfItem !== undefined && specItem.insuredItemsCarriedOutsideProperty !== undefined && specItem.replacementCost !== undefined && specItem.specifiedItemDescription !== undefined) {
            const typeOfItem = new QuestionAnswer<string>(0, QuestionEnum.typeOfItem, '', specItem.typeOfItem.uniqueId)
            const insuredItemsCarriedOutsideProperty = new QuestionAnswer<string>(1, QuestionEnum.insuredItemsCarriedOutsideProperty, '', `${specItem.insuredItemsCarriedOutsideProperty ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No}`)
            const replacementCost = new QuestionAnswer<number>(2, QuestionEnum.replacementCost, '', specItem.replacementCost)
            const specifiedItemDescription = new QuestionAnswer<string>(3, QuestionEnum.specifiedItemDescription, '', specItem.specifiedItemDescription)

            const adPage = new PageAnswers(`Specified Item ${i + 1}`, [typeOfItem, insuredItemsCarriedOutsideProperty, replacementCost, specifiedItemDescription])
            spi.subPageAnswers.push(adPage)
          }
        }
      })
    }
    return pageAnswers
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister | null {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber) as QuestionAnswer<string>

    if (!title?.value || !firstName?.value || !lastName?.value || !email?.value)
      return null

    const request = new AccountRegister()
    request.title = title.value!
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.email = email.value!
    request.dateOfBirth = dob.value!
    request.address = address.value!
    request.mobilePhone = phoneNumber.value!

    request.marketing = new Marketing()
    request.marketing.isEmailOptIn = true
    request.marketing.isTelephoneOptIn = true
    request.marketing.isSMSOptIn = true
    request.marketing.isPostOptIn = true

    request.affiliateId = affiliateId

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const title = questions.find(q => q.name === QuestionEnum.title) as QuestionAnswer<string>
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.keepingYouInformed) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const autoRenew = questions.find(q => q.name === QuestionEnum.autoRenewalOptOut) as QuestionAnswer<string>

    account.title = title?.value ?? account.title
    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone
    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address
    if (autoRenew?.value)
      account.autoRenew = autoRenew.value?.indexOf('true') !== -1 ? false : true ?? null

    return account
  }
}
