<script setup lang="ts">
import { useRoute } from 'vue-router/composables'
import { getAuthServiceConfig } from '@/auth-service'
import { computed } from 'vue'

defineProps<{
  type: 'login' | 'register'
}>()

const emit = defineEmits(['complete'])

const { endpoint, token, setToken, type: tokenType } = getAuthServiceConfig()
const returnUrl = window.location.href

const route = useRoute()

if (route.query.token) {
  setToken(route.query.token as string, 'auth')
  emit('complete')
}

const prospectToken = computed(() => tokenType === 'auth-prospect' ? token : undefined)
</script>

<template>
  <ignite-auth-service-login
    class="auth-service-login"
    :endpoint="endpoint"
    :return-url="returnUrl"
    :prospect-token="prospectToken"
    :type="type"
  />
</template>

<style lang="css" scoped>
.auth-service-login {
  margin: auto;
}
</style>
