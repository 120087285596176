import type { Dictionary } from '@/types'

export enum LinkConfigActionTypes {
  windowOpen = 'windowOpen',
  redirect = 'redirect',
  chat = 'chat',
  routerPush = 'routerPush',
  alert = 'alert',
  emit = 'emit',
  integrationJWT = 'integrationJWT',
  modal = 'modal',
}

export enum LinkConfigActionShowTypes {
  desktop = 'desktop',
  mobile = 'mobile',
}

export interface ILinkConfigActionDialogConfig {
  title: string
  message: string
  confirmText: string
}

export interface ILinkConfigModal {
  enabled?: boolean | null
  title?: string
  message?: string
  buttons: ILinkConfigSpecification[]
}

export interface ILinkConfigActionSpecification {
  type: string | LinkConfigActionTypes
  url: string | null
  queryParams?: Dictionary<string> | null
  dialogConfig: ILinkConfigActionDialogConfig | null
  integrationId: string | null
  modalConfig?: ILinkConfigModal | null
}

export interface ILinkConfigSpecification {
  name: string
  action: ILinkConfigActionSpecification
  showOn: string[]
  showForScheme?: string | null
  showForVap?: string | null
  showOnlyForRenewalStatus?: string[] | null
}
