<template>
  <div>
      <div class="modal-card">
        <header class="modal-card-head" v-if="title">
          <p class="modal-card-title">{{ title }}</p>
        </header>
          <section class="modal-card-body confirmation-message">
            <ignite-markdown>{{ message }}</ignite-markdown>
          </section>
          <footer class="modal-card-foot">
            <template v-for="(a, i) in buttons">
              <LinkConfigButton
                v-if="!hideRenewalButtonForPolicyChangeAction(a)"
                :config="a"
                :key="i"
                @click="$emit('close')"
              />
            </template>
          </footer>
      </div>
    <b-loading :is-full-page="true" />
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import LinkConfigButton from './LinkConfigButton.vue';
import { LinkConfig } from '@/configurations/IConfiguration';
import Policy from '@/services/models/Policy';
import AvailableActionsEnum from '@/services/enums/AvalableActionsEnum';

@Component({
  components: {
    IgniteMarkdown: () => import('@/components/controls/IgniteMarkdown.vue'),
    LinkConfigButton,
  },
})
export default class LinkConfigModal extends Vue {
  @Prop() title!: string;

  @Prop() message!: string;

  @Prop() buttons!: LinkConfig[];

  @Prop() policy!: Policy;

  hideRenewalButtonForPolicyChangeAction(button: LinkConfig) {
    return this.title === 'Make Policy Change' && button.name === 'Renewal' && !this.policy.availableActions.find(a => a === AvailableActionsEnum.manageRenewal);
  }
}
</script>
