import { JsonObject, JsonProperty } from 'json2typescript'
import PersonalDetails from '../PersonalDetails'
import VehicleConviction from './VehicleConviction'
import Address from '@/services/models/Address'

@JsonObject('VehicleDriverValue')
export default class VehicleDriver extends PersonalDetails {
  @JsonProperty('licenceNumber', String)
    licenceNumber: string | null = null

  @JsonProperty('licenceType', String)
    licenceType = ''

  @JsonProperty('licenceHeldYears', String, true)
    licenceHeldYears: string | null = null

  @JsonProperty('hasAccessToRegularVehicleUse', Boolean, true)
    hasAccessToRegularVehicleUse: boolean | null = null

  @JsonProperty('regularUseVehicleDetail', String, true)
    regularUseVehicleDetail: string | null = null

  @JsonProperty('relationshipToPolicyHolder', String, true)
    relationshipToPolicyHolder: string | null = null

  @JsonProperty('address', Address)
    address: Address | null = null

  @JsonProperty('hasAccessToAnotherVehicle', Boolean)
    hasAccessToAnotherVehicle = false

  @JsonProperty('driverToVehicles', [String])
    driverToVehicles: string[] | null = null

  @JsonProperty('proposerHasAccessToAdditionalDriverLicence', Boolean)
    proposerHasAccessToAdditionalDriverLicence: boolean | null = null

  @JsonProperty('hasConvictions', Boolean)
    hasConvictions: boolean | null = null

  @JsonProperty('convictions', [VehicleConviction])
    convictions: VehicleConviction[] = []
}
